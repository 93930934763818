import { Component, OnInit } from '@angular/core';

import { Permissions } from '../core/auth/permissions';

@Component({
    selector: 'vgb-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    permissions = Permissions;

    constructor() { }

    ngOnInit() {
    }

}
