<form [formGroup]="formGroup">
<mat-card (@shakeCard.done)="shakeEnd()" [@shakeCard]="shakeCardState">
  <mat-card-content>
    <img class="d-flex justify-content-center logo" src="assets/img/Logo_BK_RGB_web.png"/>

    <h1 class="d-flex justify-content-center">Online-Meldeportal</h1>
    <p>
      Aufgrund einiger grundlegender Änderungen im Onlineportal ist eine Anmeldung nur noch per E-Mail-Adresse
      und nicht mehr über die Urhebernummer möglich. In diesem Formular haben Sie die Möglichkeit Ihre
      Anmeldeinformationen auf das neue Loginverfahren zu migrieren.
    </p>
    <h3>Ihre bisherigen Zugangsdaten</h3>
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Urhebernummer *</mat-label>
          <input formControlName="creatorNumber" matInput name="login" type="number">
          <mat-error *ngFor="let creatorNumberError of (formGroup.controls.creatorNumber.errors | error)">
            {{ creatorNumberError }} </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>Passwort *</mat-label>
          <input formControlName="password" matInput name="password" type="password">
          <mat-error *ngFor="let passwordError of (formGroup.controls.password.errors | error)">
            {{ passwordError }} </mat-error>
        </mat-form-field>
      </div>
    </div>
    <h4>Mit welcher E-Mail möchten Sie sich zukünftig anmelden?</h4>
    <p>Bitte auchten Sie darauf, dass Sie auf den Posteingang der angegebenden Adresse zugreifen können. Sie
      erhalten nach der Umstellung eine E-Mail mit Anweisungen zur Aktivierung ihres Kontos an diese
      Adresse.</p>
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>E-Mail *</mat-label>
          <input formControlName="email" matInput name="email" type="email">
          <mat-error *ngFor="let emailError of (formGroup.controls.email.errors | error)">
            {{ emailError }} </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>E-Mail bestätigen *</mat-label>
          <input formControlName="emailConfirmation" matInput name="email" type="email">
          <mat-error
            *ngFor="let emailConfirmationError of (formGroup.controls.emailConfirmation.errors | error)">
            {{ emailConfirmationError }} </mat-error>
        </mat-form-field>
      </div>
    </div>
    <h4>Wie geht es weiter?</h4>
    <p>Sobald Sie dieses Formular bestätigt haben, erhalten Sie eine E-Mail mit Anweisungen zur Aktivierung
      ihres Kontos, die Sie zum Zurücksetzen
      Ihres Passworts auffordert. Sobald Sie diesen Schritt abgeschlossen haben, können Sie sich mit Ihrer
      E-Mail und dem neuen Passwort anmelden.</p>
    <strong *ngIf="errorMessage" class="d-flex justify-content-center mat-error">{{ errorMessage }}</strong>
  </mat-card-content>

  <mat-card-actions>
    <div class="row action-row">
      <div class="col-6 no-padding">
        <a [routerLink]="['/']" mat-stroked-button>Zurück zur Anmeldung</a>
      </div>
      <div class="col-6 no-padding">
        <button (click)="submit()" [disabled]="!formGroup.valid" class="button-right" color="primary"
                mat-raised-button>Jetzt
          migrieren
        </button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
</form>
