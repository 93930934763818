import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective} from '@angular/forms';
import {CreatorSearchForm, enumToArray, RightType} from './types/creator-search-form';
import {CreatorSearchService} from './creator-search.service';
import {CreatorViewModel} from './types/creator-view-model';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {environment} from '../../environments/environment';
import {transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn, fadeOut} from '../shared/animations/fade';
import {ErrorDialogComponent} from '../shared/error-dialog/error-dialog.component';
import {timer} from 'rxjs';
import 'node_modules/iframe-resizer/js/iframeResizer.contentWindow.js'

@Component({
  selector: 'vgb-creator-search',
  templateUrl: './creator-search.component.html',
  styleUrls: ['./creator-search.component.scss'],
  animations: [
    trigger('progressBarFade', [
      transition(':enter', [
        useAnimation(fadeIn)
      ]),

      transition(':leave', [
        useAnimation(fadeOut)
      ])
    ])]
})
export class CreatorSearchComponent implements OnInit {
  downloadEndpoint: string = environment.serviceUrl + "/search/file";

  public rightTypes = enumToArray(RightType);

  public formGroup: FormGroup;
  showMatchingCreators: boolean;
  showProgressBar: boolean;

  public dataSource: MatTableDataSource<CreatorViewModel>;
  public columns = ['name', 'countries', 'dismiss'];

  @ViewChild("form")
  public form: FormGroupDirective;

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  constructor(
    private formBuilder: FormBuilder,
    public creatorSearchService: CreatorSearchService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group(new CreatorSearchForm);
  }

  submit() {
    this.formGroup.disable();
    this.showProgressBar = true;
    let formValues = this.formGroup.value;

    this.creatorSearchService.getCreators(formValues.rightType, formValues.name)
      .subscribe((data) => {
        let creators: CreatorViewModel[] = data;
        this.showMatchingCreators = true;
        timer(0).subscribe(() => {
          this.dataSource = new MatTableDataSource<CreatorViewModel>(creators);
          this.dataSource.paginator = this.paginator;
          this.showProgressBar = false;
          this.showMatchingCreators = true;
          this.formGroup.enable();
        })
      }, (errorResponse) => {
        this.dialog
          .open(ErrorDialogComponent, { data: errorResponse.error })
          .afterClosed()
          .subscribe(() => {
            this.showProgressBar = false;
            this.formGroup.enable();
          })
      })
  }

  public dismissCreators(): void {
    this.dataSource = null;
    this.showMatchingCreators = false;
    this.formGroup.reset();
    this.form.resetForm();
  }

  public displayName(c: CreatorViewModel): string {
    let name = c.name;
    if (!this.isEmptyOrSpaces(c.firstName)) {
      name += ', ' + c.firstName;
    }
    return name;
  }

  private isEmptyOrSpaces(str): boolean {
    return typeof str !== "string" || str.trim().length === 0;
  }

  public isValid(): boolean {
    return this.formGroup.valid && !this.isEmptyOrSpaces(this.formGroup.value.name);
  }
}
