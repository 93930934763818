<form #form="ngForm" [formGroup]="formGroup" (ngSubmit)="submit()">

  <mat-card [@shakeCard]="shakeCardState" (@shakeCard.done)="shakeEnd()">
    <mat-card-content>
      <img src="assets/img/Logo_BK_RGB_web.png" class="d-flex justify-content-center logo" />

      <h1 class="d-flex justify-content-center">Meldeportal</h1>

      <div class="row">
        <div class="col-12 my-2">
          <mat-form-field>
            <mat-label>Urhebernummer</mat-label>
            <input type="text" name="signin.username" matInput formControlName="username" required>
          </mat-form-field>
        </div>
        <div class="col-12 my-2">
          <mat-form-field>
            <mat-label>Passwort</mat-label>
            <input type="password" name="signin.password" matInput formControlName="password" required>
          </mat-form-field>
        </div>
      </div>

      <b class="d-flex justify-content-center" id="signin-failure" *ngIf="showFailureMessage" @failureMessage>Die
        Anmeldedaten sind nicht korrekt</b>
    </mat-card-content>

    <mat-card-actions align="end">
      <a mat-button [routerLink]="['/', 'recover-password']"
        [queryParams]="{username: formGroup.get('username').value}">Passwort vergessen</a>
      <span class="spacer"></span>
      <button mat-raised-button color="primary" [disabled]="!form.valid">Login</button>

      <mat-progress-bar *ngIf="showProgressBar" @progressBarFade mode="indeterminate" color="accent"></mat-progress-bar>
    </mat-card-actions>
  </mat-card>
</form>
