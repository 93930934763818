import { Injectable } from '@angular/core';
import { environment } from 'projects/main/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { ChannelDic, MovieChannel, MovieGenre } from '../types/movie-types';
import { ExhibitionLocation } from '../types/exhibition-types';
import { map, tap } from 'rxjs/operators';
import { Language, LanguageDic } from '../types/foreign-language-report';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    static endpoint: string = environment.serviceUrl + '/configuration';
    private genreCache: MovieGenre[];
    private channelCache: MovieChannel[];
    private activeChannelCache: MovieChannel[];
    private locationCache: ExhibitionLocation[];
    private languageCache: LanguageDic;
    private prodChannelCache: MovieChannel[];
    private ChannelDicCache: ChannelDic;

    constructor(private httpClient: HttpClient) {
    }

    public getGenres(): Observable<MovieGenre[]> {
        return this.genreCache ? of(this.genreCache) : this.httpClient.get<MovieGenre[]>(`${ConfigurationService.endpoint}/genres`).pipe(tap(genres => this.genreCache = genres));
    }

    public getActiveChannels(): Observable<MovieChannel[]> {
        return this.activeChannelCache ? of(this.activeChannelCache) : this.httpClient.get<MovieChannel[]>(`${ConfigurationService.endpoint}/activeChannels`).pipe(tap(channels => this.activeChannelCache = channels));
    }

    public getChannels(): Observable<MovieChannel[]> {
        return this.channelCache ? of(this.channelCache) : this.httpClient.get<MovieChannel[]>(`${ConfigurationService.endpoint}/allChannels`).pipe(tap(channels => this.channelCache = channels));
    }

    public getProdChannels(): Observable<MovieChannel[]> {
        return this.prodChannelCache ? of(this.prodChannelCache) : this.httpClient.get<MovieChannel[]>(`${ConfigurationService.endpoint}/producingChannels`).pipe(tap(prodChannels => this.prodChannelCache = prodChannels));
    }

    public getAllChannels(): Observable<ChannelDic> {
        return this.ChannelDicCache ? of(this.ChannelDicCache) : forkJoin(this.getChannels(), this.getProdChannels()).pipe(map(([channels, prodChannels]) => {
            this.ChannelDicCache = {};
            [...channels, ...prodChannels].forEach(x => this.ChannelDicCache[x.identifier] = x.name);
            return this.ChannelDicCache;
        }));
    }

    public getLocations(): Observable<ExhibitionLocation[]> {
        return this.locationCache ? of(this.locationCache) : this.httpClient.get<ExhibitionLocation[]>(`${ConfigurationService.endpoint}/locations`).pipe(tap(locations => this.locationCache = locations));
    }

    public getLanguages(): Observable<LanguageDic> {
        return this.languageCache ? of(this.languageCache) : this.httpClient.get<Language[]>(`${ConfigurationService.endpoint}/languages`)
            .pipe(map((languages) => {
                this.languageCache = {};
                languages.forEach(x => this.languageCache[x.code] = x.name);
                return this.languageCache;
            }));
    }
}