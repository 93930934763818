import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ExhibitionType} from '../../report/types/exhibition-types';

@Pipe({
    name: 'exhibitionTypes',
})
export class ExhibitionTypesPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    public transform(): { value: ExhibitionType | number; displayText: string }[] {
        return Object.entries(ExhibitionType)
            .filter(([, value]) => typeof value === 'number')
            .map(([TypeName, tpyeValue]) => ({
              value: tpyeValue as number,
                displayText: this.translateService.instant(`exhibitionTypes.${TypeName}`),
            }));
    }
}

@Pipe({
    name: 'exhibitionType',
})
export class ExhibitionTypePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    public transform(value: ExhibitionType): string {
        return this.translateService.instant(`exhibitionTypes.${ExhibitionType[value]}`);
    }
}
