import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CoreModule } from '../core/core.module';
import { SigninComponent } from './signin.component';
import { SigninErrorComponent } from './signin-error/signin-error.component';

@NgModule({
    declarations: [SigninComponent, SigninErrorComponent],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        CoreModule,
    ],
    exports: [SigninComponent]
})
export class SigninModule { }
