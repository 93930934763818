<mat-card>
  <mat-card-content>
    <h1 class="d-flex justify-content-center">Meldeportal</h1>
    <h2 class="d-flex justify-content-center">Passwort vergessen</h2>

    <p>
      Sollten Sie Ihr Passwort vergessen haben, so füllen Sie dieses Formular bitte aus.
      Wir senden Ihnen dann neue Zugangsdaten per Post zu.
    </p>

    <form #form="ngForm" [formGroup]="formGroup">
      <div class="row">
        <div class="col-6">
          <mat-form-field class="autosize">
            <mat-label>Urhebernummer</mat-label>
            <input type="text" name="recover-password.username" matInput formControlName="username" required>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <mat-form-field class="autosize">
            <mat-label>Vorname</mat-label>
            <input type="text" name="recover-password.firstName" matInput formControlName="firstName" required>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="autosize">
            <mat-label>Nachname</mat-label>
            <input type="text" name="recover-password.lastName" matInput formControlName="lastName" required>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-9">
          <mat-form-field>
            <mat-label>Straße</mat-label>
            <input type="text" name="recover-password.street" matInput formControlName="street" required>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Hausnr.</mat-label>
            <input type="text" name="recover-password.houseNumber" matInput formControlName="houseNumber" required>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <mat-form-field>
            <mat-label>PLZ</mat-label>
            <input type="text" name="recover-password.postcode" matInput formControlName="postcode" required>
          </mat-form-field>
        </div>
        <div class="col-9">
          <mat-form-field>
            <mat-label>Ort</mat-label>
            <input type="text" name="recover-password.city" matInput formControlName="city" required>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>E-Mail-Adresse für Rückfragen</mat-label>
            <input type="email" name="recover-password.mail" matInput formControlName="mail" required>
          </mat-form-field>
        </div>
      </div>
    </form>

    <b class="d-flex justify-content-center" *ngIf="showSuccessMessage" @message>Ihre Anfrage
      wurde entgegengenommen</b>
    <b class="d-flex justify-content-center" *ngIf="showFailureMessage" @message>Bei der
      Anfrage ist ein Fehler aufgetreten</b>
  </mat-card-content>

  <mat-card-actions align="end">
    <a mat-button [routerLink]="['/']">zurück zum Login</a>
    <span class="spacer"></span>
    <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="submit()">absenden</button>
    <mat-progress-bar *ngIf="showProgressBar" @progressBarFade mode="indeterminate" color="accent"></mat-progress-bar>
  </mat-card-actions>
</mat-card>
