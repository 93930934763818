import {animate, style, transition, trigger, useAnimation} from '@angular/animations';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {shake} from 'ng-animate';
import {Subject} from 'rxjs';
import {IdentityService} from '../core/auth/identity.service';
import {fadeIn, fadeOut} from '../shared/animations/fade';
import {SigninForm} from './signin-form';
import {LoginError} from '../report/types/login-error';
import {SigninErrorComponent} from './signin-error/signin-error.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

@Component({
    selector: 'vgb-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss'],
    animations: [
        trigger('progressBarFade', [
            transition(':enter', [
                useAnimation(fadeIn)
            ]),

            transition(':leave', [
                useAnimation(fadeOut)
            ])
        ]),
        trigger('failureMessage', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('200ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                animate('150ms', style({ opacity: 0 }))
            ])
        ]),
        trigger('shakeCard', [
            transition('static => shake', useAnimation(shake, {
                params: { timing: .6 }
            }))
        ])
    ]
})
export class SigninComponent implements OnInit, OnDestroy {
    formGroup: FormGroup;
    showFailureMessage: boolean;
    showProgressBar: boolean;
    shakeCardState: string = 'static';

    private unsub = new Subject<void>();

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private dialog: MatDialog,
        private identityService: IdentityService
    ) {
      console.log("construct")
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group(new SigninForm);
    }

    submit() {
        this.formGroup.disable();
        this.showProgressBar = true;
        this.showFailureMessage = false;

        let formValues = this.formGroup.value;
       this.identityService.signIn(formValues)
            .subscribe(() => {
                this.showProgressBar = false;
                this.router.navigate([this.identityService.redirectUrl || '/']);
            }, (loginError: LoginError) => {
                this.formGroup.enable();
                this.showProgressBar = false;
                if (loginError.error === "client_deleted") {
                    this.dialog.open(SigninErrorComponent);
                } else {
                    this.showFailureMessage = true;
                    setTimeout(() => this.shakeCardState = 'shake');
                }
            })
    }

    ngOnDestroy(): void {
        this.unsub.next();
        this.unsub.complete();
    }

    shakeEnd() {
        this.shakeCardState = 'static';
    }
}
