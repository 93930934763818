import { Injectable } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

import { Permissions } from './permissions';
import { Role } from './roles';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {
    constructor(
        private permissionService: NgxPermissionsService,
        private roleService: NgxRolesService
    ) {
    }

    public applyRoles(roles: Role[]) {
        this.flushAll();

        roles.forEach(r => {
            this.loadPermissions(r.permissions);
            this.roleService.addRole(r.name, r.permissions);
        });
    }

    public flushAll() {
        this.permissionService.flushPermissions();
        this.roleService.flushRoles();
    }

    public addPermission(permission: Permissions) {
        this.permissionService.addPermission(permission);
    }

    public removePermission(permission: Permissions) {
        this.permissionService.removePermission(permission.toString());
    }

    public addRole(role: Role) {
        this.loadPermissions(role.permissions);
        this.roleService.addRole(role.name, role.permissions);
    }

    public removeRole(role: Role) {
        this.roleService.removeRole(role.name);
        role.permissions.forEach(p => this.permissionService.removePermission(p.toString()));
    }

    public hasRole(roleName: string): boolean {
        return this.roleService.getRole(roleName) ? true : false;
    }

    public hasPermission(permission: Permissions): Promise<boolean> {
        return this.permissionService.hasPermission(permission.toString());
    }

    private loadPermissions(permissions: Permissions[]) {
        this.permissionService.addPermission(permissions);
    }
}
