import { animate, animation, style } from '@angular/animations';

export const fadeIn = animation([
    style({ opacity: 0 }),
    animate('{{duration}} linear', style({ opacity: 1 }))
], { params: { duration: '100ms' } });

export const fadeOut = animation([
    animate('{{duration}} linear', style({ opacity: 0 }))
], { params: { duration: '100ms' } });
