import { PageTextEditorComponent } from './editor/editor.component';

export class PageTextEditorRef {
    readonly instance: PageTextEditorComponent;

    constructor(
        instance: PageTextEditorComponent
    ) {
        this.instance = instance;
    }
}