import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ExhibitionCategory} from '../../report/types/exhibition-types';

@Pipe({
    name: 'exhibitionCategories',
})
export class ExhibitionCategoriesPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    public transform(): { value: ExhibitionCategory | number; displayText: string }[] {
        return Object.entries(ExhibitionCategory)
            .filter(([, value]) => typeof value === 'number')
            .map(([categoryName, categoryValue]) => ({
              value: categoryValue as number,
                displayText: this.translateService.instant(`exhibitionCategories.${categoryName}`),
            }));
    }
}

@Pipe({
    name: 'exhibitionCategory',
})
export class ExhibitionCategoryPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    public transform(value: ExhibitionCategory): string {
        return this.translateService.instant(`exhibitionCategories.${ExhibitionCategory[value]}`);
    }
}
