import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { Permissions } from '../core/auth/permissions';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: [Permissions.ViewDashboard],
                redirectTo: '/user/edit-self'
            }
        }
    }
];

@NgModule({
    declarations: [DashboardComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        CoreModule,
        SharedModule
    ],
    exports: [
        DashboardComponent
    ]
})
export class DashboardModule { }
