import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[vgbNoNegativ]'
})
export class NoNegativDirective {
  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    this.handleEvent(event);
  }

  private readonly allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  private readonly actionKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Tab', 'Enter'];

  private handleEvent(event: KeyboardEvent) {
    if (this.isValidKey(event.key)) {
      return;
    }

    event.preventDefault();
  }

  private isValidKey(key: string): boolean {
    return this.allowedKeys.includes(key) || this.actionKeys.includes(key);
  }
}
