import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormFieldsetComponent} from './form-fieldset/fieldset.component';
import {IsbnModule} from './isbn/isbn.module';
import {PageTextModule} from './onscreen-help/page-text/page-text.module';
import {CurrencyInputDirective} from './currencyInput/currencyInput.directive';
import {BookTypePipe, BookTypesPipe} from 'projects/main/src/app/shared/pipes/book-types.pipe';
import {ExhibitionSizePipe, ExhibitionSizesPipe} from './pipes/exhibition-sizes.pipe';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {PreventEnterDirective} from './directives/prevent-enter.directive';
import {GetCountryNamePipe} from './pipes/country-codes.pipe';
import {NoNegativDirective} from './directives/no-negativ.directive';
import {EnumToKeyvaluePipe} from './pipes/enum-to-keyvalue.pipe';
import {MovieGenreNamePipe} from './pipes/movie-genre-name.pipe';
import {RightTypePipe} from '../creator-search/right-type.pipe';
import {ErrorPipe} from './pipes/error.pipe';
import {SelectFilterComponent, SelectFilterOptionDirective} from './select-filter/select-filter.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {LanguagePipe} from './pipes/language.pipe';
import {ChannelPipe} from './pipes/channel.pipe';
import {DecimalDirective} from './directives/decimal.directive';
import {FormatDatePipe} from './pipes/date-time.pipe';
import {PreventBackspaceDirective} from './directives/prevent-backspace.directive';
import {NavigationDirective} from './directives/navigation.directive';
import {RemoveDialogComponent} from './remove-dialog/remove-dialog.component';
import {BookLanguagePipe, BookLanguagesPipe} from './pipes/book-languages.pipe';
import {ExhibitionCategoriesPipe, ExhibitionCategoryPipe} from './pipes/exhibtion-categories.pipe';
import {ExhibitionTypePipe, ExhibitionTypesPipe} from './pipes/exhibtion-types.pipe';
import {PrintMediumPipe, PrintMediumsPipe} from './pipes/print-mediums.pipe';
import {IllustrationCountCategoriesPipe, IllustrationCountCategoryPipe} from './pipes/illustration-counts.pipe';
import {InformationDialogComponent} from './information-dialog/information-dialog.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [
    FormFieldsetComponent,
    ErrorDialogComponent,
    CurrencyInputDirective,
    BookLanguagesPipe,
    BookLanguagePipe,
    BookTypesPipe,
    BookTypePipe,
    IllustrationCountCategoriesPipe,
    IllustrationCountCategoryPipe,
    RightTypePipe,
    ExhibitionSizesPipe,
    ExhibitionSizePipe,
    ExhibitionCategoriesPipe,
    ExhibitionCategoryPipe,
    ExhibitionTypesPipe,
    ExhibitionTypePipe,
    PrintMediumsPipe,
    PrintMediumPipe,
    PreventEnterDirective,
    NoNegativDirective,
    DecimalDirective,
    PreventBackspaceDirective,
    GetCountryNamePipe,
    EnumToKeyvaluePipe,
    MovieGenreNamePipe,
    FormatDatePipe,
    ChannelPipe,
    ErrorPipe,
    LanguagePipe,
    SelectFilterComponent,
    SelectFilterOptionDirective,
    NavigationDirective,
    RemoveDialogComponent,
    InformationDialogComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [provideNgxMask()],
  exports: [
    IsbnModule,
    ErrorDialogComponent,
    FormFieldsetComponent,
    PageTextModule,
    CurrencyInputDirective,
    BookLanguagesPipe,
    BookLanguagePipe,
    BookTypesPipe,
    BookTypePipe,
    IllustrationCountCategoriesPipe,
    IllustrationCountCategoryPipe,
    RightTypePipe,
    ExhibitionSizePipe,
    ExhibitionSizesPipe,
    PreventEnterDirective,
    NoNegativDirective,
    DecimalDirective,
    PreventBackspaceDirective,
    GetCountryNamePipe,
    EnumToKeyvaluePipe,
    MovieGenreNamePipe,
    FormatDatePipe,
    ChannelPipe,
    ErrorPipe,
    LanguagePipe,
    SelectFilterComponent,
    SelectFilterOptionDirective,
    NavigationDirective,
    RemoveDialogComponent,
    ExhibitionCategoriesPipe,
    ExhibitionCategoryPipe,
    ExhibitionTypesPipe,
    ExhibitionTypePipe,
    PrintMediumsPipe,
    PrintMediumPipe,
    InformationDialogComponent,
  ],
})
export class SharedModule {
}
