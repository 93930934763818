import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CoreModule } from '../core/core.module';
import { RecoverPasswordComponent } from './recover-password.component';

@NgModule({
    declarations: [RecoverPasswordComponent],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        CoreModule,
    ],
    exports: [RecoverPasswordComponent]
})
export class RecoverPasswordModule { }
