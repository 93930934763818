import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './core/auth/guards/auth.guard';
import {CreatorSearchComponent} from './creator-search/creator-search.component';
import {MigrateComponent} from './migrate/migrate.component';
import {TokenComponent} from './core/auth/token.component';
import {SigninComponent} from "./signin/signin.component";
import {SignedInGuard} from "./core/auth/guards/signed-in-guard.guard";
import {RecoverPasswordComponent} from "./recover-password/recover-password.component";

const routes: Routes = [
  {
    path: 'signin',
    component: SigninComponent,
    canActivate: [SignedInGuard]
  },
  {
    path: 'migrate',
    component: MigrateComponent,
  },
  {
    path: 'creator-search',
    component: CreatorSearchComponent,
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
    canActivate: [SignedInGuard]
  },
  {
    path: 'token',
    component: TokenComponent,
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'show-reports',
    loadChildren: () => import('./show-reports/show-reports.module').then(m => m.ShowReportsModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
