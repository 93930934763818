import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    Injectable,
    Input,
    OnDestroy
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NavigationPosition } from '../types/nav-position';
import { filter } from 'rxjs/operators';

@Injectable()
/**
 * Provide with your component:
 * @Component({
 *   ...
 *   providers: [NavigationService]
 * })
 */
export class NavigationService {
    private _positions: NavigationPosition[] = [];
    readonly selectedInputIndex$: BehaviorSubject<NavigationPosition> =
        new BehaviorSubject<NavigationPosition>({ row: 0, col: 0 });

    public registerPosition(p: NavigationPosition) {
        this._positions.push(p);
    }

    public updatePosition(pOld: NavigationPosition, pNew: NavigationPosition) {
        const i = this._positions.indexOf(pOld);
        this._positions[i] = pNew;
    }

    public unregisterPosition(p: NavigationPosition) {
        const i = this._positions.indexOf(p);
        this._positions.splice(i, 1);
    }

    public moveLeft(p: NavigationPosition): NavigationPosition {
        const positions = this.sortPositions();
        const i = positions.indexOf(p);
        return i > 0 ? positions[i - 1] : null;
    }

    public moveRight(p: NavigationPosition): NavigationPosition {
        const positions = this.sortPositions();
        const i = positions.indexOf(p);
        return positions[i + 1];
    }

    public moveUp(p: NavigationPosition): NavigationPosition {
        const positions = this.sortPositions().reverse();
        let newPosition = positions.find(x => x.row < p.row && x.col === p.col);
        if (!newPosition) {
            newPosition = positions.find(x => x.row < p.row && x.col < p.col);
        }
        return newPosition;
    }

    public moveDown(p: NavigationPosition): NavigationPosition {
        const positions = this.sortPositions();
        let newPosition = positions.find(x => x.row > p.row && x.col === p.col);
        if (!newPosition) {
            newPosition = positions.find(x => x.row > p.row && x.col < p.col);
        }
        return newPosition;
    }

    public isMatch(a: NavigationPosition, b: NavigationPosition): boolean {
        return a.row === b.row && a.col === b.col;
    }

    private sortPositions(): NavigationPosition[] {
        return this._positions.sort((a, b) => {
            if (a.row === b.row) {
                return a.col - b.col;
            }
            return a.row - b.row
        });
    }
}

@Directive({
    selector: '[vgbNavigation]'
})
/**
 * First, provide NavigationService in your component:
 * @Component({
 *   ...
 *   providers: [NavigationService]
 * })
 *
 * Then, simply specify the position of the input element:
 * <input [enterFocusIndex]="{ row: 0, col: 1 }">
 *
 * @param position - position that defines order of focused items
 */
export class NavigationDirective implements AfterViewInit, OnDestroy {

    private _position: NavigationPosition;
    private _navSubscription: Subscription;

    @Input('position') set position(position: NavigationPosition) {
        if (this._position) {
            this.navigationService.updatePosition(this._position, position);
        }
        this._position = position;
    }


    @HostListener('keydown.ArrowLeft', ['$event']) onArrowLeft(e: KeyboardEvent) {
        const newPosition = this.navigationService.moveLeft(this._position);
        this.onArrowKeyPressed(newPosition);
        e.preventDefault();
    }

    @HostListener('keydown.ArrowRight', ['$event']) onArrowRight(e: KeyboardEvent) {
        const newPosition = this.navigationService.moveRight(this._position);
        this.onArrowKeyPressed(newPosition);
        e.preventDefault();
    }

    @HostListener('keydown.ArrowUp', ['$event']) onArrowUp(e: KeyboardEvent) {
        const newPosition = this.navigationService.moveUp(this._position);
        this.onArrowKeyPressed(newPosition);
        e.preventDefault();
    }

    @HostListener('keydown.ArrowDown', ['$event']) onArrowDown(e: KeyboardEvent) {
        const newPosition = this.navigationService.moveDown(this._position);
        this.onArrowKeyPressed(newPosition);
        e.preventDefault();
    }

    @HostListener('keydown.Enter', ['$event']) onEnter(e: KeyboardEvent) {
        const newPosition = this.navigationService.moveRight(this._position);
        this.onArrowKeyPressed(newPosition);
        e.preventDefault();
    }

    constructor(
        public readonly element: ElementRef,
        private readonly navigationService: NavigationService
    ) {
    }

    ngAfterViewInit() {
        this.navigationService.registerPosition(this._position);
        this._navSubscription = this.navigationService.selectedInputIndex$
            .pipe(filter(x => this.isMatch(x)))
            .subscribe(() => this.focusElement());
    }

    ngOnDestroy(): void {
        this._navSubscription.unsubscribe();
        this.navigationService.unregisterPosition(this._position);
    }

    private focusElement() {
        window.setTimeout(() => {
            this.element.nativeElement.focus();
        });
    }

    private onArrowKeyPressed(position: NavigationPosition) {
        if (position) {
            this.navigationService.selectedInputIndex$.next(position);
        }
    }

    private isMatch(position: NavigationPosition): boolean {
        return this.navigationService.isMatch(this._position, position)
    }
}
