import { CdkPortalOutlet, ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector, LOCALE_ID, Optional } from '@angular/core';
import { environment } from 'projects/main/src/environments/environment';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { ckConfig } from './ckeditor-config';
import { PageTextDisplayComponent } from './display/display.component';
import { PageTextEditorComponent } from './editor/editor.component';
import { PageText } from './page-text';
import { PageTextEditorRef } from './page-text-editor-ref';
import { PAGE_TEXT_DATA, PAGE_TEXT_DEFAULT_OPTIONS, PAGE_TEXT_EDITOR_CONFIG } from './page-text-tokens';

@Injectable({
    providedIn: 'root'
})
export class PageTextService {
    static systemApi = `${environment.serviceUrl}/system`;

    constructor(
        private injector: Injector,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) public currentLocale: string,
        @Optional() @Inject(PAGE_TEXT_DEFAULT_OPTIONS) private defaultOptions: any
    ) { }

    public attachEditor(host: CdkPortalOutlet, location: string, locale?: string, config?: any): PageTextEditorRef {
        config = this.applyConfigDefaults(config, this.defaultOptions || ckConfig);

        let pageText = this.getPageText(location, locale || this.currentLocale)
            .pipe(map(t => t || new PageText(null, location, locale || this.currentLocale)));
        let injector = this.createEditorInjector(pageText, config);
        let portal = new ComponentPortal(PageTextEditorComponent, null, injector);
        let instance = host.attachComponentPortal(portal).instance;

        let ref = new PageTextEditorRef(instance);
        return ref;
    }

    public attachDisplay(host: CdkPortalOutlet, location: string, locale?: string) {
        let pageText = this.getPageText(location, locale || this.currentLocale);
        let injector = this.createDisplayInjector(pageText);
        let portal = new ComponentPortal(PageTextDisplayComponent, null, injector);
        host.attachComponentPortal(portal);
    }

    public getPageTexts(location?: string, locale?: string): Observable<PageText[]> {
        let pageTextsUrl = `${PageTextService.systemApi}/pageTexts`;

        return this.httpClient.get<PageText[]>(pageTextsUrl, {
            params: {
                location: encodeURIComponent(location),
                locale: locale
            }
        }).pipe(share());
    }

    public getPageText(location?: string, locale?: string): Observable<PageText> {
        return this.getPageTexts(location, locale)
            .pipe(map(r => r.length > 0 ? r[0] : null))
    }

    public savePageText(pageText: PageText): Observable<PageText> {
        let pageTextsUrl = `${PageTextService.systemApi}/pageTexts`;
        if (pageText.id) {
            pageTextsUrl += `/${pageText.id}`;
            return this.httpClient.put<PageText>(pageTextsUrl, pageText);
        }
        else {
            return this.httpClient.post<PageText>(pageTextsUrl, pageText);
        }
    }

    private createEditorInjector(data: PageText | Observable<PageText>, config?: any): PortalInjector {
        let injectionTokens = new WeakMap();
        injectionTokens.set(PAGE_TEXT_DATA, data);
        injectionTokens.set(PAGE_TEXT_EDITOR_CONFIG, config);
        return new PortalInjector(this.injector, injectionTokens);
    }

    private createDisplayInjector(data: PageText | Observable<PageText>): PortalInjector {
        let injectionTokens = new WeakMap();
        injectionTokens.set(PAGE_TEXT_DATA, data);
        return new PortalInjector(this.injector, injectionTokens);
    }

    private applyConfigDefaults(config?: any, defaults?: any) {
        return { ...defaults, ...config };
    }
}
