import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'vgb-remove-dialog',
    templateUrl: './remove-dialog.component.html',
    styleUrls: ['./remove-dialog.component.scss']
})
export class RemoveDialogComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
