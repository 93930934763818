export class CreatorSearchForm {
  rightType: RightType;
  name: string;

  constructor(
    rightType?: RightType,
    name?: string
  ) {
    this.rightType = rightType;
    this.name = name;
  }
}

export enum RightType {
  Online = 1,
  Reproduction = 2,
  Resale = 3
}

export function enumToArray(obj) {
  return Object.entries(obj).filter(([key, value]) => {
    return typeof value === 'string';
  }).map(([key, value]) => {
    return {key, value};
  });
}
