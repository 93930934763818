export class PageText {
    id: number;
    location: string;
    locale: string;
    contents: string;

    constructor(
        id?: number,
        location?: string,
        locale?: string,
        contents: string = ''
    ) {
        this.id = id;
        this.location = location;
        this.locale = locale;
        this.contents = contents;
    }
}