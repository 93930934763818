import {FocusMonitor} from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NgControl} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatLegacyFormFieldControl as MatFormFieldControl} from '@angular/material/legacy-form-field';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ISBN} from '../../../report/types/isbn';

@Component({
    selector: 'vgb-isbn-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: IsbnInputComponent }]
})
export class IsbnInputComponent implements MatFormFieldControl<ISBN>, ControlValueAccessor, OnInit, OnChanges, OnDestroy {
    @Input()
    withPrefix: boolean = true;

    public get value(): ISBN | null {
        return !this.empty ? this.parts.value : null;
    }
    @Input()
    public set value(isbn: ISBN | null) {
        this.parts = this.formBuilder.group(isbn);
        this.stateChangesSubject.next();
    }

    public get placeholder() {
        return this.placeholderValue;
    }
    @Input()
    public set placeholder(placeholder: string) {
        this.placeholderValue = placeholder;
        this.stateChangesSubject.next();
    }

    public get required() {
        return this.requiredValue;
    }
    @Input()
    public set required(required: boolean) {
        this.requiredValue = required;
        this.stateChangesSubject.next();
    }

    public get disabled() {
        return this.disabledValue;
    }
    @Input()
    public set disabled(disabled: boolean) {

        if (disabled) {
            this.applyFormGroupValues(new ISBN());
            this.disabledValue = false;
            this.disableControls();
        }
        else {
            if (this.disabledValue != disabled) {
                this.ngControl.control.markAsTouched();
            }
            this.disabledValue = true;
            this.enableControls();
        }

        this.stateChangesSubject.next();
    }

    @Input()
    public errorStateMatcher: ErrorStateMatcher;

    @HostBinding()
    public id: string = `vgb-isbn-input-${IsbnInputComponent.nextId++}`;
    public controlType = 'vgb-isbn-input';

    @HostBinding('class.floating')
    public get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @HostBinding('attr.aria-describedby')
    public describedBy = '';

    public static nextId = 0;
    public stateChanges: Observable<void>;
    public focused = false;
    public get complete() {
        return this.ngControl.valid;
    }
    public get empty() {
        let isbn: ISBN = this.parts ? this.parts.value : new ISBN();
        return !isbn.prefix && !isbn.registrationGroup && !isbn.registrant && !isbn.publication && !isbn.checkdigit;
    }
    public get errorState() {
        return this.ngControl.enabled && this.ngControl.touched && !this.ngControl.valid;
    }

    parts: FormGroup;

    private stateChangesSubject = new Subject<void>();
    private placeholderValue: string;
    private requiredValue: boolean;
    private disabledValue: boolean = false;
    private unsubscribe = new Subject<void>();

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        private formBuilder: FormBuilder,
        private focusMonitor: FocusMonitor,
        private elementRef: ElementRef<HTMLElement>
    ) {
        this.stateChanges = this.stateChangesSubject.asObservable();
        this.buildFormGroup(new ISBN);
        this.parts.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                this.onChange(value)
            });

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        this.focusMonitor.monitor(this.elementRef.nativeElement, true)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(origin => {
                this.focused = !!origin;
                this.stateChangesSubject.next();
            });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['value'] && this.value) {
            this.applyFormGroupValues(this.value);
            this.stateChangesSubject.next();
        }
    }

    ngOnDestroy() {
        this.stateChangesSubject.complete();
        this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            this.elementRef.nativeElement.querySelector('input').focus();
        }
    }

    onChange = (isbn: ISBN) => { };
    onTouched = () => { };

    writeValue(value: ISBN): void {
        this.applyFormGroupValues(value);
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private buildFormGroup(isbn: ISBN) {
        this.parts = this.formBuilder.group({
            prefix: isbn.prefix || '',
            registrationGroup: isbn.registrationGroup || '',
            registrant: isbn.registrant || '',
            publication: isbn.publication || '',
            checkdigit: isbn.checkdigit || ''
        });
    }

    private applyFormGroupValues(isbn: ISBN) {
        this.parts.controls.prefix.setValue(isbn.prefix || '');
        this.parts.controls.registrationGroup.setValue(isbn.registrationGroup || '');
        this.parts.controls.registrant.setValue(isbn.registrant || '');
        this.parts.controls.publication.setValue(isbn.publication || '');
        this.parts.controls.checkdigit.setValue(isbn.checkdigit || '');
    }

    private disableControls() {
        this.parts.controls.prefix.disable();
        this.parts.controls.registrationGroup.disable();
        this.parts.controls.registrant.disable();
        this.parts.controls.publication.disable();
        this.parts.controls.checkdigit.disable();
    }
    private enableControls() {
        this.parts.controls.prefix.enable();
        this.parts.controls.registrationGroup.enable();
        this.parts.controls.registrant.enable();
        this.parts.controls.publication.enable();
        this.parts.controls.checkdigit.enable();
    }
}
