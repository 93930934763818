import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NgxPermissionsModule} from 'ngx-permissions';
import {MaterialUsageModule} from '../material-usage/material-usage.module';
import {AppBarComponent} from './app-bar.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialUsageModule,
    NgxPermissionsModule
  ],
  exports: [
    AppBarComponent
  ],
  declarations: [AppBarComponent]
})
export class AppBarModule {
}
