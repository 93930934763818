import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '../../report/data-services/config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'getChannelName'
})
export class ChannelPipe implements PipeTransform {

    constructor(private configService: ConfigurationService) { }

    public transform(value: number, args?: any): Observable<string> {
        return this.configService.getAllChannels().pipe(map(x => x[value] || 'Der gesuchte Sender kann nicht gefunden werden.'));
    }
}