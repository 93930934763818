import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[vgbDecimal]'
})
export class DecimalDirective {

  private readonly allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  private readonly actionKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Tab', 'Enter'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    this.handleEvent(event);
  }

  private handleEvent(event: KeyboardEvent) {
    const isValidKey = this.isValidKey(event.key);
    if (isValidKey || this.isValidComma(event)) {
      return;
    }

    event.preventDefault();
  }

  private isValidKey(key: string): boolean {
    return this.allowedKeys.includes(key) || this.actionKeys.includes(key);
  }

  private isValidComma(event: KeyboardEvent): boolean {
    return event.key === ',' &&
      (event.target as any).value.indexOf(',') === -1;
  }
}
