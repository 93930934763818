<h1 mat-dialog-title>Info</h1>
<mat-dialog-content>{{data.message}}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="data.onCancel !== undefined && data.onCancel()" >Abbrechen</button>
  <button color="primary"
          mat-button
          mat-dialog-close
  (click)="data.onOk()">OK
  </button>
</mat-dialog-actions>
