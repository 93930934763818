import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {IdentityService} from '../identity.service';

@Injectable({
  providedIn: 'root'
})
export class SignedInGuard implements CanActivate {

  constructor(
    private router: Router,
    private identityService: IdentityService
  ) {

  }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.identityService.isSignedIn
            .pipe(
                map(loggedIn => loggedIn ? this.router.createUrlTree(['/']) : true),
            );
    }
}
