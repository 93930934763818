import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AppBarModule } from './app-bar/app-bar.module';
import { AppMenuModule } from './app-menu/app-menu.module';
import { AuthModule } from './auth/auth.module';
import { MaterialUsageModule } from './material-usage/material-usage.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    exports: [
        TranslateModule,
        AuthModule,
        MaterialUsageModule,
        AppBarModule,
        AppMenuModule,
    ]
})
export class CoreModule { }
