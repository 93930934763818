import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PrintImageMedium} from '../../report/types/single-image-report';

@Pipe({
    name: 'printMediums',
})
export class PrintMediumsPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    public transform(): { value: PrintImageMedium | number; displayText: string }[] {
        return Object.entries(PrintImageMedium)
            .filter(([key, value]) => typeof value === 'number')
            .map(([key, value]) => ({
              value: value as number,
                displayText: this.translateService.instant(`printMedium.${key}`),
            }));
    }
}

@Pipe({
    name: 'printMedium',
})
export class PrintMediumPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    public transform(value: PrintImageMedium): string {
        return this.translateService.instant(`printMedium.${PrintImageMedium[value]}`);
    }
}
