import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'projects/main/src/environments/environment';

export const authConfig: AuthConfig = {
    issuer: environment.identity.issuer,
    tokenEndpoint: environment.identity.tokenEndpoint,
    oidc: true,
    requireHttps: environment.identity.requireHttps,
    clientId: environment.identity.clientId,
    scope: 'openid profile offline_access'
}
