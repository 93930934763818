import {transition, trigger, useAnimation} from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular/ckeditor.component';
import {fadeIn, fadeOut} from 'ng-animate';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PageText} from '../page-text';
import {PAGE_TEXT_DATA, PAGE_TEXT_EDITOR_CONFIG} from '../page-text-tokens';
import InlineEditor from "@ckeditor/ckeditor5-build-inline";

@Component({
    selector: 'vgb-page-text-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss'],
    animations: [
        trigger('progressBarFade', [
            transition(':enter', [
                useAnimation(fadeIn)
            ]),

            transition(':leave', [
                useAnimation(fadeOut)
            ])
        ])
    ]
})
export class PageTextEditorComponent implements OnInit, OnDestroy {
    @Output()
    update = new EventEmitter<PageText>();
    ckEditor = InlineEditor;
    pageText: PageText;

    @HostBinding('class.disabled')
    disabled: boolean;
    saveDisabled: boolean = true;
    showProgressBar: boolean;
    progressBarError: boolean;

    private updatedContents: string;
    private unsub = new Subject<void>();

  @ViewChild('ckEditorComponent') ckEditorComponent: ElementRef;

    constructor(
        @Inject(PAGE_TEXT_DATA) pageText: PageText | Observable<PageText>,
        @Inject(PAGE_TEXT_EDITOR_CONFIG) public ckConfig: any
    ) {
        if (pageText instanceof Observable) {
            pageText.pipe(takeUntil(this.unsub)).subscribe(t => this.updatePageText(t));
        }
        else {
            this.pageText = pageText || new PageText;
        }
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.unsub.next();
        this.unsub.complete();
    }

    ckChange(event: ChangeEvent) {
        this.saveDisabled = false;
        this.updatedContents = event.editor.getData();
    }

    save() {
        let clone = JSON.parse(JSON.stringify(this.pageText));
        clone.contents = this.updatedContents;
        this.update.emit(clone);
    }

    public updatePageText(pageText: PageText) {
        this.pageText = pageText || new PageText;

        //workaround for Angular 8 - assigning data doesn't work
        setTimeout(() => {
            //@ts-ignore
            this.ckEditorComponent.writeValue(this.pageText.contents)
        })
    }

    public enable() {
        this.disabled = false;
    }

    public disable() {
        this.disabled = true;
    }

    public showProgress() {
        this.showProgressBar = true;
    }

    public hideProgress() {
        this.showProgressBar = false;
    }

    public showError() {
        this.progressBarError = true;
    }

    public clearError() {
        this.progressBarError = false;
    }
}
