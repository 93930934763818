import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'enumToKeyvalue'
  })
export class EnumToKeyvaluePipe implements PipeTransform{
    public transform(input: any) {
        return Object.entries(input).filter(([key, value]) => {
            return typeof value === 'number';
        }).map(([key, value]) => {
            return {key, value};
        });
    }
}