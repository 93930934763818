import { Permissions } from './permissions';

export class Role {
    name: Roles;
    permissions: Permissions[];
}

export enum Roles {
    Creator = 'CREATOR',
    ActiveCreator = 'ACTIVECREATOR',
    ReporterOther = 'REPORTEROTHER',
    Other = 'OTHER',
    ReporterMovies = 'REPORTERMOVIES',
    Movies = 'MOVIES',
    Editor = 'EDITOR',
    Admin = 'ADMIN',
    Producer = 'PRODUCER',
    Publisher = 'PUBLISHER',
}

export const vgbRoles: Role[] = [
    {
        name: Roles.Creator, permissions: [
            Permissions.EditSelfAddress,
        ],
    },
    {
        name: Roles.ActiveCreator, permissions: [
            Permissions.ViewTopNav,
            Permissions.ViewDashboard,
            Permissions.EditSelfContact,
            Permissions.EditSelfMail,
            Permissions.EditSelfPassword,
            Permissions.ActiveCreator,
        ],
    },
    {
        name: Roles.ReporterOther, permissions: [
            Permissions.ReportSingleWeb,
            Permissions.ReportSinglePrint,
            Permissions.ReportSingleTelevision,
            Permissions.ReportSingleDigitalProduct,
            Permissions.ReportOwnWeb,
            Permissions.ReportBook,
            Permissions.ReportFees,
            Permissions.ReportOwnWeb,
            Permissions.ReportArchitecturalArt,
            Permissions.ReportPublisherUsage,
            Permissions.ReportExhibitions,
        ],
    },
    {
        name: Roles.Other, permissions: [
            Permissions.ViewReportSingleWebPage,
            Permissions.ViewReportSinglePrintPage,
            Permissions.ViewReportSingleTelevisionPage,
            Permissions.ViewReportSingleDigitalProductPage,
            Permissions.ViewReportOwnWebPage,
            Permissions.ViewReportBookPage,
            Permissions.ViewReportFeesPage,
            Permissions.ViewReportArchitecturalArtPage,
            Permissions.ViewReportPublisherUsage,
            Permissions.ViewReportExhibitionsPage,
            Permissions.ViewPastReports,
        ],
    },
    {
        name: Roles.ReporterMovies, permissions: [
            Permissions.ReportMovies,
        ],
    },
    {
        name: Roles.Movies, permissions: [
            Permissions.ViewReportMoviesPage,
            Permissions.ViewPastReports,
            Permissions.ReportAllClaims,
        ],
    },
    {
        name: Roles.Producer, permissions: [
            Permissions.ReportProducerClaim,
            Permissions.ViewReportMoviesPage,
            Permissions.ViewPastReports,
        ],
    },
    {
        name: Roles.Publisher, permissions: [
            Permissions.ReportPublisherBook,
            Permissions.ViewReportPublisherBookPage,
        ],
    },
    {
        name: Roles.Editor, permissions: [
            Permissions.ViewTopNav,
            Permissions.ViewDashboard,

            Permissions.ReportSingleWeb,
            Permissions.ReportSinglePrint,
            Permissions.ReportSingleTelevision,
            Permissions.ReportSingleDigitalProduct,
            Permissions.ReportBook,
            Permissions.ReportPublisherBook,
            Permissions.ReportFees,
            Permissions.ReportOwnWeb,
            Permissions.ReportExhibitions,
            Permissions.ReportMovies,
            Permissions.ReportArchitecturalArt,
            Permissions.ReportPublisherUsage,

            Permissions.ViewReportSingleWebPage,
            Permissions.ViewReportSinglePrintPage,
            Permissions.ViewReportSingleTelevisionPage,
            Permissions.ViewReportSingleDigitalProductPage,
            Permissions.ViewReportBookPage,
            Permissions.ViewReportPublisherBookPage,
            Permissions.ViewReportFeesPage,
            Permissions.ViewReportOwnWebPage,
            Permissions.ViewReportExhibitionsPage,
            Permissions.ViewReportArchitecturalArtPage,
            Permissions.ViewReportMoviesPage,
            Permissions.ViewReportPublisherUsage,
            Permissions.EditPageTexts,
        ],
    }, {
        name: Roles.Admin, permissions: [
            Permissions.ViewTopNav,
            Permissions.ViewDashboard,

            Permissions.ReportSingleWeb,
            Permissions.ReportSinglePrint,
            Permissions.ReportSingleTelevision,
            Permissions.ReportSingleDigitalProduct,
            Permissions.ReportBook,
            Permissions.ReportPublisherBook,
            Permissions.ReportFees,
            Permissions.ReportOwnWeb,
            Permissions.ReportExhibitions,
            Permissions.ReportArchitecturalArt,
            Permissions.ReportMovies,
            Permissions.ReportPublisherUsage,

            Permissions.ViewReportSingleWebPage,
            Permissions.ViewReportSinglePrintPage,
            Permissions.ViewReportSingleTelevisionPage,
            Permissions.ViewReportSingleDigitalProductPage,
            Permissions.ViewReportBookPage,
            Permissions.ViewReportFeesPage,
            Permissions.ViewReportOwnWebPage,
            Permissions.ViewReportExhibitionsPage,
            Permissions.ViewReportArchitecturalArtPage,
            Permissions.ViewReportMoviesPage,
            Permissions.ViewReportPublisherUsage,
            Permissions.EditPageTexts,
            Permissions.AdminReport,
        ],
    },
];
