import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '../../core/core.module';
import { IsbnInputComponent } from './input/input.component';
import { ResizableInputDirective } from './input/resizable-input.directive';

@NgModule({
    declarations: [IsbnInputComponent, ResizableInputDirective],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CoreModule
    ],
    exports: [IsbnInputComponent]
})
export class IsbnModule { }
