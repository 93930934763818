import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RightType } from './types/creator-search-form';

@Pipe({
    name: "rightType"
})
export class RightTypePipe implements PipeTransform {
    constructor(private translateService: TranslateService) { }

    public transform(value: RightType): string {
        return this.translateService.instant(`rightTypes.${value}`);
    }
}