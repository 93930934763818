import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'projects/main/src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IdentityService } from '../auth/identity.service';
import { Permissions } from '../auth/permissions';

@Component({
    selector: 'vgb-app-bar',
    templateUrl: './app-bar.component.html',
    styleUrls: ['./app-bar.component.scss'],
})
export class AppBarComponent implements OnInit, OnDestroy {
    @HostBinding('style.z-index')
    zIndex: number | string = '';

    showUserTools: boolean;
    creatorNumber: Observable<string>;
    permissions = Permissions;

    private unsubscribe = new Subject<void>();

    constructor(
        private identityService: IdentityService
    ) {
        this.identityService.loginState
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((state) => {
                this.showUserTools = state;
            });
    }

    ngOnInit() {
        this.creatorNumber = this.identityService.creatorNumber;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    signOut() {
        this.identityService.logout();
    }
}
