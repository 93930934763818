import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
    declarations: [],
    imports: [CommonModule, NgxPermissionsModule.forChild()],
    exports: [NgxPermissionsModule],
})
export class AuthModule { }

