import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { MigrateComponent } from './migrate.component';
import { AuthModule } from "../core/auth/auth.module";
import { IdentityService } from "../core/auth/identity.service";

@NgModule({
    declarations: [MigrateComponent],
    providers: [IdentityService],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        CoreModule,
        SharedModule,
    ],
    exports: [MigrateComponent]
})
export class MigrateModule { }
