import {animate, style, transition, trigger} from '@angular/animations';
import {Component, HostBinding} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {IdentityService} from './core/auth/identity.service';

@Component({
    selector: 'vgb-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('blockInitialTransitions', [
            transition(':enter', [])
        ]),
        trigger('appBarTransition', [
            transition(':enter', [
                style({ transform: 'translateY(-100%)' }),
                animate('200ms', style({ transform: 'translateY(0)' }))
            ]),
            transition(':leave', [
                animate('200ms', style({ transform: 'translateY(-100%)' }))
            ])
        ])
    ]
})
export class AppComponent {
    @HostBinding('@blockInitialTransitions')
    block = true;

    @HostBinding('class.hasAppBar')
    showAppBar: boolean;
    loading: boolean;

    constructor(
        private router: Router,
        private identityService: IdentityService,
        private titleService: Title,
    ) {

      this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading = true;
                    break;
                }
                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loading = false;
                    break;
                }
            }
        });

        this.identityService.loginState
            .subscribe(state => {
                this.showAppBar = state;
            });

        let titlePrefix = environment['titlePrefix'] ? `[${environment['titlePrefix'].toUpperCase()}] ` : '';
        this.titleService.setTitle(titlePrefix + 'VG Bild-Kunst Online-Meldeportal');
    }
}
