import {Pipe, PipeTransform} from '@angular/core';
import {ExhibitionSize} from 'projects/main/src/app/report/types/exhibition-types';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'exhibitionSizes'
})
export class ExhibitionSizesPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  public transform(): { value: ExhibitionSize | number, displayText: string, hint: string }[] {
    return Object.entries(ExhibitionSize)
      .filter(([key, value]) => typeof value === "number")
      .map(([key, value]) => ({
        value: value as number,
        displayText: this.translateService.instant(`exhibitionSizes.${key}.label`),
        hint: this.translateService.instant(`exhibitionSizes.${key}.hint`)
      }));
  }

}

@Pipe({
  name: "exhibitionSize"
})
export class ExhibitionSizePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  public transform(value: ExhibitionSize): { displayText: string, hint: string } {
    return {
      displayText: this.translateService.instant(`exhibitionSizes.${ExhibitionSize[value]}.label`),
      hint: this.translateService.instant(`exhibitionSizes.${ExhibitionSize[value]}.hint`)
    };
  }
}
