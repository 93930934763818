import { Permissions } from './permissions';

export class Role {
  name: Roles;
  permissions: Permissions[];
}

export enum Roles {
  Editor = 'EDITOR',
  Admin = 'ADMIN',
  User = 'USER',
  Verlag = 'VERLAG',
  BildKunst = 'BILDKUNST',
  Film = 'FILM'
}

export const vgbRoles: Role[] = [
  {
    name: Roles.User, permissions: [
      Permissions.ViewTopNav,
      Permissions.ViewDashboard,
      Permissions.EditSelfContact,
      Permissions.EditSelfMail,
      Permissions.EditSelfPassword,
      Permissions.EditSelfAddress,
      Permissions.ActiveCreator,
    ]
  },
  {
    name: Roles.BildKunst, permissions: [
      Permissions.ReportSingleWeb,
      Permissions.ReportSinglePrint,
      Permissions.ReportSingleTelevision,
      Permissions.ReportSingleDigitalProduct,
      Permissions.ReportOwnWeb,
      Permissions.ReportBook,
      Permissions.ReportFees,
      Permissions.ReportOwnWeb,
      Permissions.ReportArchitecturalArt,
      Permissions.ReportPublisherUsage,
      Permissions.ViewReportSingleWebPage,
      Permissions.ViewReportSinglePrintPage,
      Permissions.ViewReportSingleTelevisionPage,
      Permissions.ViewReportSingleDigitalProductPage,
      Permissions.ViewReportOwnWebPage,
      Permissions.ViewReportBookPage,
      Permissions.ViewReportFeesPage,
      Permissions.ViewReportArchitecturalArtPage,
      Permissions.ViewReportPublisherUsage,
      Permissions.ViewReportExhibitionsPage,
      Permissions.ViewPastReports,
      Permissions.ReportExhibitions,
    ],
  },
  {
    name: Roles.Film, permissions: [
      Permissions.ViewReportMoviesPage,
      Permissions.ViewPastReports,
      Permissions.ReportAllClaims,
      Permissions.ReportMovies,
      Permissions.ReportProducerClaim,
      Permissions.ViewReportMoviesPage,
      Permissions.ViewPastReports,
    ],
  },
  {
    name: Roles.Verlag, permissions: [
      Permissions.ReportPublisherBook,
      Permissions.ViewReportPublisherBookPage,
    ],
  },
  {
    name: Roles.Editor, permissions: [
      Permissions.ViewTopNav,
      Permissions.ViewDashboard,

      Permissions.ReportSingleWeb,
      Permissions.ReportSinglePrint,
      Permissions.ReportSingleTelevision,
      Permissions.ReportSingleDigitalProduct,
      Permissions.ReportBook,
      Permissions.ReportPublisherBook,
      Permissions.ReportFees,
      Permissions.ReportOwnWeb,
      Permissions.ReportExhibitions,
      Permissions.ReportMovies,
      Permissions.ReportArchitecturalArt,
      Permissions.ReportPublisherUsage,

      Permissions.ViewReportSingleWebPage,
      Permissions.ViewReportSinglePrintPage,
      Permissions.ViewReportSingleTelevisionPage,
      Permissions.ViewReportSingleDigitalProductPage,
      Permissions.ViewReportBookPage,
      Permissions.ViewReportPublisherBookPage,
      Permissions.ViewReportFeesPage,
      Permissions.ViewReportOwnWebPage,
      Permissions.ViewReportExhibitionsPage,
      Permissions.ViewReportArchitecturalArtPage,
      Permissions.ViewReportMoviesPage,
      Permissions.ViewReportPublisherUsage,
      Permissions.EditPageTexts,
    ],
  },
  {
    name: Roles.Admin, permissions: [
      Permissions.ViewTopNav,
      Permissions.ViewDashboard,

      Permissions.ReportSingleWeb,
      Permissions.ReportSinglePrint,
      Permissions.ReportSingleTelevision,
      Permissions.ReportSingleDigitalProduct,
      Permissions.ReportBook,
      Permissions.ReportPublisherBook,
      Permissions.ReportFees,
      Permissions.ReportOwnWeb,
      Permissions.ReportExhibitions,
      Permissions.ReportArchitecturalArt,
      Permissions.ReportMovies,
      Permissions.ReportPublisherUsage,

      Permissions.ViewReportSingleWebPage,
      Permissions.ViewReportSinglePrintPage,
      Permissions.ViewReportSingleTelevisionPage,
      Permissions.ViewReportSingleDigitalProductPage,
      Permissions.ViewReportBookPage,
      Permissions.ViewReportFeesPage,
      Permissions.ViewReportOwnWebPage,
      Permissions.ViewReportExhibitionsPage,
      Permissions.ViewReportArchitecturalArtPage,
      Permissions.ViewReportMoviesPage,
      Permissions.ViewReportPublisherUsage,
      Permissions.EditPageTexts,
      Permissions.AdminReport,
    ],
  },
];
