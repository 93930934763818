import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[vgbPreventEnter]'
})
export class PreventEnterDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown.enter', ['$event']) onkeydown(event: KeyboardEvent) {  
    this.preventEnter(event);
  }

  private preventEnter(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}