import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[vgbPreventBackspace]'
})
export class PreventBackspaceDirective {
    constructor(private el: ElementRef) { }

    @HostListener('window:keydown.Backspace', ['$event']) onkeydown(event: KeyboardEvent) {
        this.stay(event);
    }

    private stay(event: any) {
        const tagName = event.target.tagName
        if (tagName !== "INPUT") {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}