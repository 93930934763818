export enum Permissions {
    ViewTopNav = 'ViewTopNav',
    ViewDashboard = 'ViewDashBoard',
    AdminReport = 'AdminReport',
    ActiveCreator = 'ActiveCreator',

    ReportSingleWeb = 'ReportSingleWeb',
    ReportSinglePrint = 'ReportSinglePrint',
    ReportSingleTelevision = 'ReportSingleTelevision',
    ReportSingleDigitalProduct = 'ReportSingleDigitalProduct',
    ReportOwnWeb = 'ReportOwnWeb',
    ReportBook = 'ReportBook',
    ReportPublisherBook = 'ReportPublisherBook',
    ReportFees = 'ReportFees',
    ReportExhibitions = 'ReportExhibitions',
    ReportArchitecturalArt = 'ReportArchitecutralArt',
    ReportMovies = 'ReportMovies',
    ReportProducerClaim = 'ReportProducerClaim',
    ReportAllClaims = 'ReportAllClaims',
    ReportPublisherUsage = 'ReportPublisherUsage',

    ViewPastReports = 'ViewPastReports',
    ViewReportSingleWebPage = 'ViewReportSingleWebPage',
    ViewReportSinglePrintPage = 'ViewReportSinglePrintPage',
    ViewReportSingleTelevisionPage = 'ViewReportSingleTelevisionPage',
    ViewReportSingleDigitalProductPage = 'ViewReportSingleDigitalProductPage',
    ViewReportOwnWebPage = 'ViewReportOwnWebPage',
    ViewReportBookPage = 'ViewReportBookPage',
    ViewReportPublisherBookPage = 'ViewReportPublisherBookPage',
    ViewReportFeesPage = 'ViewReportFeesPage',
    ViewReportExhibitionsPage = 'ViewReportExhibitionsPage',
    ViewReportArchitecturalArtPage = 'ViewReportArchitecturalArtPage',
    ViewReportMoviesPage = 'ViewReportMoviesPage',
    ViewReportPublisherUsage = 'ViewReportPublisherUsage',

    EditSelfAddress = 'EditSelfAddress',
    EditSelfMail = 'EditSelfMail',
    EditSelfPassword = 'EditSelfPassword',
    EditSelfContact = 'EditSelfContact',
    EditPageTexts = 'EditPageTexts',
}
