import {Directive, ElementRef, HostBinding, HostListener, Input, Optional, Renderer2, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {formatNumber} from '@angular/common';
import {MatLegacyFormFieldControl as MatFormFieldControl} from '@angular/material/legacy-form-field';
import {Subject, Subscription} from 'rxjs';
import {FocusMonitor} from '@angular/cdk/a11y';
import {filter, sample} from 'rxjs/operators';

let id = 0;

@Directive({
  selector: 'input[vgbCurrency]',
  host: {
    'type': 'text',
    'style': 'text-align: right;',
    'class': 'mat-input-element'
  },
  providers: [
    { provide: MatFormFieldControl, useExisting: CurrencyInputDirective, multi: true },
  ],
})
export class CurrencyInputDirective implements MatFormFieldControl<number>, ControlValueAccessor {
  public stateChanges = new Subject<void>();
  @HostBinding()
  public id = `currencyInput-${id++}`;
  @Input()
  public placeholder: string;
  public focused: boolean;
  public shouldLabelFloat: boolean;
  private subscription: Subscription;

  get required() {
    return this._required;
  }
  @Input()
  set required(req) {
    this._required = !!req;
    if(req){
      this.renderer.setAttribute(this.elementRef.nativeElement, 'required', '');
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'required');
    }
    this.stateChanges.next();
  }
  private _required = false;
  private _disabled = false;
  public set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  public get disabled(): boolean {
    return this._disabled;
  }
  public get errorState(): boolean {
    return this.ngControl ? this.ngControl.invalid : false;
  }
  public controlType?: string = 'currency-input';
  public autofilled?: boolean = false;

  @HostBinding('attr.aria-describedby')
  public describedBy = '';

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }
  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.focus();
    }
  }

  public get value(): number {
    return this.convertToNumber(this.elementRef.nativeElement.value);
  }

  public set value(val: number|null) {
    const str = this.convertToString(val||0);
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', str);
    if(this.onChangeFn && !this.disabled) {
      this.onChangeFn(this.value);
    }
    this.stateChanges.next();
  }

  get empty() {
    return this.elementRef.nativeElement.value == null || this.elementRef.nativeElement === '';
  }

  private onChangeFn: (val: number) => void;
  private onTouchFn: () => void;

  private changeQueue = new Subject<string>();

  constructor(@Optional() @Self() public ngControl: NgControl, private elementRef: ElementRef, private renderer: Renderer2, fm: FocusMonitor) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.subscription = this.changeQueue.pipe(
      sample(fm.monitor(elementRef.nativeElement, true).pipe(filter(origin => !origin)))
    ).subscribe(str => {
      this.writeValue(str);
    });

    fm.monitor(elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(val: string|number|null): void {
    if(typeof val === 'string'){
      this.value = this.convertToNumber(val);
    } else if(typeof val === 'number'){
      this.value = val;
    } else {
      this.value = 0;
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', '');
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
    }
    this._disabled = isDisabled;
  }

  @HostListener('keypress', ['$event'])
  public onInputChange(evt: KeyboardEvent): void {
    this.onTouchFn();
    const eleValue = this.elementRef.nativeElement.value;
    if (!/[0-9,]|(del)/i.test(evt.key) || ((evt.key === ',' || evt.key === 'Del') && (eleValue.includes(',') || eleValue.lenght === 0))) {
      evt.preventDefault();
      evt.stopPropagation();
      evt.returnValue = false;
      return;
    }
  }

  @HostListener('keyup', ['$event'])
  public onKeyUp(evt: KeyboardEvent): void {
    if (window.getSelection().toString() !== '' || [38, 40, 37, 39].includes(evt.keyCode)) { // user is inserting text Or using Arrow Keys
        return;
    }
    this.changeQueue.next(this.elementRef.nativeElement.value);
  }

  private convertToNumber(str: string): number {
    const num = Number.parseFloat(str.replace(/\./g, '').replace(/,/g, '.'));
    return Number.isNaN(num) ? 0 : num;
  }

  private convertToString(num: number): string {
    let str = formatNumber(num, 'de-DE', '.2-2');
    return str;
  }
}
