import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwksValidationHandler, OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import * as JwtDecode from 'jwt-decode';
import { environment } from 'projects/main/src/environments/environment';
import { from, Observable, ReplaySubject, throwError, timer } from 'rxjs';
import { catchError, map, mapTo, shareReplay, tap } from 'rxjs/operators';

import { authConfig } from './auth-config';
import { AuthorizationService } from './authorization.service';
import { Roles, vgbRoles } from './roles';
import { AccessToken } from './types/access_token';
import { Identity } from './types/identity';
import { RecoverPasswordRequest } from './types/recover-password-request';
import { SignInRequest } from './types/signin-request';
import {jwtDecode} from "jwt-decode";

@Injectable({
    providedIn: 'root'
})
export class IdentityService {
    public isSignedIn: Observable<boolean>;
    public current: Observable<Identity>;
    public redirectUrl: string;

    private currentSubject = new ReplaySubject<Identity>();
    private isSignedInSubject = new ReplaySubject<boolean>();

    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private oauthService: OAuthService,
        private authorizationService: AuthorizationService
    ) {
        this.current = this.currentSubject.asObservable().pipe(shareReplay(1));
        this.isSignedIn = this.isSignedInSubject.asObservable().pipe(shareReplay(1));

        this.setupOAuthService(this.oauthService);
        this.initOidc(this.oauthService);
        this.handleStoredLogin();
    }

    public signIn(request: SignInRequest): Observable<boolean> {
        let fetch = this.oauthService.fetchTokenUsingPasswordFlow(
            request.username,
            request.password
        );

        return from(fetch).pipe(
            map((response) => {
                this.applyToken((response as any).access_token);
                this.initTokenRefresh();
                return true;
            }),
            catchError((httpError) => throwError(httpError.error))
        )
    }

    public recoverPassword(request: RecoverPasswordRequest): Promise<boolean> {
        return this.httpClient.post(`${environment.serviceUrl}/user/passwordRecovery`, request)
            .pipe(mapTo(true)) //todo: handle different errors?
            .toPromise();
    }

    public signOut(): void {
        this.oauthService.logOut();
        this.isSignedInSubject.next(false);
        this.redirectUrl = '';
    }

    public refreshToken(): Observable<any> {
        return from(this.oauthService.refreshToken())
            .pipe(
                tap((response: TokenResponse) => {
                    this.applyToken(response.access_token);
                    this.initTokenRefresh();
                }, () => {
                    this.invalidateState();
                    this.redirectToSignin();
                })
            );
    }

    public hasAddressLock(): boolean {
        return !this.authorizationService.hasRole(Roles.ActiveCreator);
    }

    private applyToken(tokenString: string) {
        let identity = this.buildIdentityFromAccessToken(tokenString);
        this.isSignedInSubject.next(true);
        this.currentSubject.next(identity);
        this.applyRoles(identity.oidcRoles || []);
    }

    private invalidateState() {
        this.isSignedInSubject.next(false);
        this.currentSubject.next(null);
        this.applyRoles([]);
    }

    private redirectToSignin() {
        this.router.navigate(['/', 'signin']);
    }

    private handleStoredLogin(): boolean {
        let state = this.oauthService.hasValidAccessToken();

        if (state) {
            this.applyToken(this.oauthService.getAccessToken());
            this.initTokenRefresh();
        }
        else {
            this.invalidateState();
        }

        return state;
    }

    private setupOAuthService(oauthService: OAuthService) {
        oauthService.configure(authConfig);
        oauthService.tokenValidationHandler = new JwksValidationHandler();
    }

    private initOidc(oauthService: OAuthService) {
        oauthService.loadDiscoveryDocument(`${environment.identity.issuer}.well-known/openid-configuration`);
    }

    private buildIdentityFromAccessToken(tokenString: string): Identity {
        let token: AccessToken = jwtDecode(tokenString);

        return {
            creatorNumber: token.nameid,
            oidcRoles: token.role instanceof Array ? token.role : token.role ? [token.role] : []
        }
    }

    private applyRoles(roles: string[]) {
        let rolePresets = vgbRoles.filter(r => roles.some(x => x.toUpperCase() == r.name));
        this.authorizationService.applyRoles(rolePresets);
    }

    private initTokenRefresh() {
        let expiresAt: number = this.oauthService.getAccessTokenExpiration();
        if (expiresAt) {
            let expiresIn = expiresAt - new Date().valueOf();
            let refreshIn = expiresIn - 100 * 60 * 5; //refresh 5 minutes before access token expires
            timer(refreshIn).subscribe(() => this.refreshToken().subscribe() );
        }
    }
}
