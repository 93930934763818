<div [formGroup]="form">
  <mat-form-field>
    <mat-select [compareWith]="compareWith" [value]="value" #matSelect [formControlName]="controlName"
      (selectionChange)="onSelect.emit($event.value)"
      (openedChange)="searchField = ''; applyFilter(); searchFieldElem.focus()" [placeholder]="placeholder">
      <input #searchFieldElem name="searchField" matInput class="search-field" [(ngModel)]="searchField"
        [ngModelOptions]="{ standalone: true }" (ngModelChange)="applyFilter()" placeholder="Suche...">
      <mat-option *ngFor="let obj of displayOptions" [value]="obj.value">
        <ng-template [ngTemplateOutlet]="obj.templateRef"></ng-template>
      </mat-option>
    </mat-select>
    <mat-error *ngFor="let error of (form.controls[controlName].errors | error)"> {{ error }} </mat-error>
  </mat-form-field>
</div>