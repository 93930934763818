export class ISBN {
    prefix: number;
    registrationGroup: number;
    registrant: number;
    publication: number;
    checkdigit: string;

    constructor(
        prefix?: number,
        registrationGroup?: number,
        registrant?: number,
        publication?: number,
        checkdigit?: string
    ) {
        this.prefix = prefix;
        this.registrationGroup = registrationGroup;
        this.registrant = registrant;
        this.publication = publication;
        this.checkdigit = checkdigit;
    }
}