import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'vgb-form-fieldset',
    templateUrl: './fieldset.component.html',
    styleUrls: ['./fieldset.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FormFieldsetComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
