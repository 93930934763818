import { animate, style, transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { IdentityService } from '../core/auth/identity.service';
import { fadeIn, fadeOut } from '../shared/animations/fade';
import { RecoverPasswordForm } from './recover-password-form';

@Component({
    selector: 'vgb-recover-password',
    templateUrl: './recover-password.component.html',
    styleUrls: ['./recover-password.component.scss'],
    animations: [
        trigger('progressBarFade', [
            transition(':enter', [
                useAnimation(fadeIn)
            ]),

            transition(':leave', [
                useAnimation(fadeOut)
            ])
        ]),
        trigger('message', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('200ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                animate('150ms', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
    formGroup: FormGroup;
    showProgressBar: boolean;
    showSuccessMessage: boolean;
    showFailureMessage: boolean;

    private unsub = new Subject<void>();

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private identityService: IdentityService
    ) { }

    ngOnInit() {
        this.formGroup = this.formBuilder.group(new RecoverPasswordForm);
        this.formGroup.get('mail').setValidators([Validators.email, Validators.maxLength(100)]);

        this.route.queryParams
            .pipe(
                takeUntil(this.unsub),
                filter(p => p.username)
            )
            .subscribe(p => this.formGroup.get('username').setValue(p.username));
    }

    submit() {
        this.formGroup.disable();
        this.showProgressBar = true;
        this.showSuccessMessage = false;
        this.showFailureMessage = false;

        let formValues = this.formGroup.value;
        this.identityService.recoverPassword(formValues)
            .then(() => {
                this.showProgressBar = false;
                this.showSuccessMessage = true;
            })
            .catch(() => {
                this.formGroup.enable();
                this.showProgressBar = false;
                this.showFailureMessage = true;
            })
    }

    ngOnDestroy(): void {
        this.unsub.next();
        this.unsub.complete();
    }
}
