import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreatorSearchComponent} from './creator-search.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '../core/core.module';
import {SharedModule} from '../shared/shared.module';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    MatPaginatorModule
  ],
  declarations: [CreatorSearchComponent]
})
export class CreatorSearchModule { }
