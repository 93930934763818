import { Component, Inject, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PageText } from '../page-text';
import { PAGE_TEXT_DATA } from '../page-text-tokens';
import { tap, map } from 'rxjs/operators';

@Component({
    selector: 'vgb-page-text-display',
    templateUrl: './display.component.html',
    styleUrls: ['./display.component.css']
})
export class PageTextDisplayComponent implements OnInit {
    public pageText: Observable<PageText>;

    constructor(
        @Inject(PAGE_TEXT_DATA) pageText: PageText | Observable<PageText>
    ) {
        const observable = pageText instanceof Observable ? pageText : of(pageText);
        this.pageText = observable.pipe(
            map((pageText) => this.modifyHrefTarget(pageText))
        );
    }

    public ngOnInit(): void {
    }

    private modifyHrefTarget(pageText: PageText): PageText {
        return pageText ? new PageText(pageText.id, pageText.location, pageText.locale, pageText.contents.replace(/<a (href="mailto:.+?")>/g, (match, ...groups: string[]) => match.replace(groups[0], groups[0] +' target="_self"'))): pageText;
    }

}