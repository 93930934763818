import { CdkPortalOutlet } from '@angular/cdk/portal';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AuthorizationService } from 'projects/main/src/app/core/auth/authorization.service';
import { Permissions } from 'projects/main/src/app/core/auth/permissions';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { PageTextEditorRef } from '../page-text-editor-ref';
import { PageTextService } from '../page-text.service';

@Component({
    selector: 'vgb-page-text',
    templateUrl: './page-text.component.html',
    styleUrls: ['./page-text.component.scss']
})
export class PageTextComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(CdkPortalOutlet, { static: true })
    portalOutlet: CdkPortalOutlet;

    @Input()
    url: string;

    canEdit: boolean;
    permissions = Permissions;

    private unsub = new Subject<void>();
    public isIe: boolean;

    constructor(
        private authorizationService: AuthorizationService,
        private pageTextService: PageTextService
    ) { }

    ngOnInit() {
        /* Don't show Editor if Microsoft Internet Explorer detected. */
        this.isIe = navigator.userAgent.includes('MSIE') || navigator.appVersion.includes('Trident/')
        this.renderDependingOnPermissions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        let urlChange = changes['url'];
        if (urlChange && !urlChange.firstChange) {
            this.renderDependingOnPermissions();
        }
    }

    ngOnDestroy() {
        this.unsub.next();
        this.unsub.complete();
    }

    toggleDebug(state: boolean) {
        this.renderDependingOnPermissions(state);
    }

    private renderDependingOnPermissions(override?: boolean) {
        this.authorizationService.hasPermission(Permissions.EditPageTexts)
            .then(p => {
                this.canEdit = override != null ? override : p;
                if (this.canEdit && !this.isIe) {
                    this.showEditor();
                }
                else {
                    this.showDisplay();
                }
            });
    }

    private showEditor() {
        this.portalOutlet.detach();
        let editorRef = this.pageTextService.attachEditor(this.portalOutlet, this.url);
        this.checkEditorUpdates(editorRef);
    }

    private showDisplay() {
        this.portalOutlet.detach();
        this.pageTextService.attachDisplay(this.portalOutlet, this.url);
    }

    private checkEditorUpdates(editorRef: PageTextEditorRef) {
        let instance = editorRef.instance;
        instance.update
            .pipe(
                takeUntil(this.unsub),
                tap(() => {
                    instance.disable();
                    instance.showProgress();
                }),
                switchMap(text => this.pageTextService.savePageText(text))
            )
            .subscribe(
                update => {
                    instance.updatePageText(update);
                    instance.enable();
                    instance.hideProgress();
                },
                () => {
                    instance.enable();
                    instance.showError();
                });
    }
}
