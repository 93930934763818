import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'projects/main/src/environments/environment';
import {Observable, of} from 'rxjs';
import {MigrateUserViewModel} from '../migrate/types/migrate-user-view-model';
import {catchError, map} from "rxjs/operators";
import {UpdateUnverifiedEmailRequest} from "../migrate/types/update-unverified-email-request";
import {VerifyRolesResult} from "./types/verify-roles-result";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private endpoint: string = environment.serviceUrl + '/user';

  constructor(private httpClient: HttpClient) {
  }

  public migrateCreator(viewModel: MigrateUserViewModel): Observable<void> {
    return this.httpClient.post<void>(`${this.endpoint}/migrateCreator`, viewModel);
  }

  public sendVerifyEmail(email: string): Observable<void> {
    return this.httpClient.post<void>(`${this.endpoint}/sendVerifyEmail`, { email: email });
  }

  public updateUnverifiedEmail(request: UpdateUnverifiedEmailRequest): Observable<void> {
    return this.httpClient.put<void>(`${this.endpoint}/updateUnverifiedEmail`, request);
  }


  public verifyRoles(): Observable<VerifyRolesResult> {
    return this.httpClient.post<void>(`${this.endpoint}/verifyRoles`, {}, { observe: 'response' }).pipe(
      map((res) => {
        switch (res.status) {
          case 200:
            return VerifyRolesResult.Verified;
          case 205:
            return VerifyRolesResult.Updated;
          default:
            return VerifyRolesResult.Error;
        }
      }),
      catchError(() => {
        return of(VerifyRolesResult.Error);
      })
    );
  }
}

