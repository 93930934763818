import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vgb-signin-error',
  templateUrl: './signin-error.component.html',
  styleUrls: ['./signin-error.component.scss']
})
export class SigninErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
