export class RecoverPasswordForm {
    username: string;
    firstName: string;
    lastName: string;
    street: string;
    houseNumber: string;
    postcode: string;
    city: string;
    mail: string;

    constructor(
        username?: string,
        firstName?: string,
        lastName?: string,
        street?: string,
        houseNumber?: string,
        postcode?: string,
        city?: string,
        mail?: string
    ) {
        this.username = username;
        this.firstName = firstName;
        this.lastName = lastName;
        this.street = street;
        this.houseNumber = houseNumber;
        this.postcode = postcode;
        this.city = city;
        this.mail = mail;
    }
}