import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

import {authConfig} from './auth-config';
import {AuthorizationService} from './authorization.service';
import {IdentityClaims} from './identity-claims';
import {OAuthService} from 'angular-oauth2-oidc';
import {vgbRoles} from './roles';
import {DialogService} from "../../shared/dialog.service";
import {VerifyRolesResult} from "../../services/types/verify-roles-result";
import {UserService} from "../../services/user.service";
import {jwtDecode} from "jwt-decode";

type DecodedTokenAccessToken = {
  creatorNumber: string
  role: string[]
  email: string
};

@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  public readonly creatorNumber: Observable<string>;
  public readonly claims: Observable<IdentityClaims>;
  public readonly loginState: Observable<boolean>;
  private readonly claimsSubject = new ReplaySubject<IdentityClaims>(1);
  private readonly loginStateSubject = new BehaviorSubject<boolean>(this.isCurrentlyLoggedIn());
  private readonly creatorNumberSubject = new BehaviorSubject<string>(this.getCreatorNumberFromToken());

  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private dialog: DialogService,
    private authorizationService: AuthorizationService,
    private userService: UserService,
  ) {
    this.claims = this.claimsSubject.asObservable();
    this.loginState = this.loginStateSubject.asObservable();
    this.creatorNumber = this.creatorNumberSubject.asObservable();
    this.configOAuthService();
    this.registerEventHandlers();
    this.oauthService.loadDiscoveryDocumentAndTryLogin()
      .then(() => this.onDiscoveryDocumentLoaded())
      .catch((_e) => this.onLoginError());
    this.applyStoredToken();
  }

  private applyStoredToken() {
    if (this.oauthService.hasValidAccessToken()) {
      this.applyRoles(this.getRolesFromToken());
    }
  }

  public decodedAccessToken(): DecodedTokenAccessToken | null {
    if (!this.oauthService.hasValidAccessToken()) {
      return null;
    }
    return jwtDecode(this.oauthService.getAccessToken());
  }

  private getCreatorNumberFromToken() {
    const token = this.decodedAccessToken();
    return token ? token.creatorNumber : null;
  }

  private getRolesFromToken() {
    const token = this.decodedAccessToken();
    return token ? token.role : null;
  }

  public login(origin?: string) {
    this.creatorNumberSubject.next(this.getCreatorNumberFromToken());
    this.oauthService.initCodeFlow(origin);
  }

  public logout(): void {
    this.oauthService.logOut(false);
  }

  private registerEventHandlers(): void {
    this.oauthService.events.subscribe((e) => {
      switch (e.type) {
        case 'token_received':
          this.handleTokenReceived();
          break;
        case 'user_profile_loaded':
        case 'token_refreshed':
          this.loginStateSubject.next(this.isCurrentlyLoggedIn());
          break;
        case 'logout':
          this.onLogout();
          break;
        case 'session_changed':
        case 'session_terminated':
        case 'token_expires':
        case 'token_validation_error':
          this.loginStateSubject.next(this.isCurrentlyLoggedIn());
          break;
      }
    });
  }

  private handleTokenReceived() {
    if (!this.router.url.startsWith('/token')) {
      return;
    }
    this.verifyRoles();
  }

  private onDiscoveryDocumentLoaded() {
    this.loginStateSubject.next(this.isCurrentlyLoggedIn());
  }

  private onLoginSuccess() {
    this.invalidateUser();
    this.creatorNumberSubject.next(this.getCreatorNumberFromToken());
    this.applyRoles(this.getRolesFromToken());
    this.router.navigate(['/']);
  }

  private onLoginError() {
    return this.dialog.info(`Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.`)
      .afterClosed().pipe().subscribe(() => this.logout());
  }

  private onRolesChanged() {
    this.oauthService.logOut(true);
    this.login();
  }

  private invalidateUser() {
    this.loginStateSubject.next(this.isCurrentlyLoggedIn());
    this.creatorNumberSubject.next(null);
    this.authorizationService.flushAll();
  }

  private onLogout() {
    this.loginStateSubject.next(this.isCurrentlyLoggedIn());
  }

  public isCurrentlyLoggedIn(): boolean {
    return (
      this.oauthService.hasValidIdToken() &&
      this.oauthService.hasValidAccessToken() &&
      this.getCreatorNumberFromToken() !== null
    );
  }

  private applyRoles(roles: string[]) {
    let rolePresets = vgbRoles.filter(r => roles.some(x => x.toUpperCase() == r.name));
    this.authorizationService.applyRoles(rolePresets);
  }

  private configOAuthService(): void {
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
  }

  public initUpdateEmail(): void {
    this.oauthService.initCodeFlow(undefined, { kc_action: 'UPDATE_EMAIL' });
  }

  private verifyRoles() {
    this.userService.verifyRoles().subscribe(result => {
        switch (result) {
          case VerifyRolesResult.Verified:
            this.onLoginSuccess();
            break;
          case VerifyRolesResult.Updated:
            this.onRolesChanged();
            break;
          case VerifyRolesResult.Error:
            this.onLoginError();
            break;
        }
      }
    );
  }
}
