import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IllustrationCountCategory} from '../../report/types/illustration-count-category';

@Pipe({
    name: 'illustrationCountCategories',
})
export class IllustrationCountCategoriesPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    public transform(): { value: IllustrationCountCategory | number; displayText: string }[] {
        return Object.entries(IllustrationCountCategory)
            .filter(([, value]) => typeof value === 'number')
            .map(([typeName, typeValue]) => ({
              value: typeValue as number,
                displayText: this.translateService.instant(`publisherBookIllustrationCounts.${typeName}`),
            }));
    }
}

@Pipe({
    name: 'illustrationCountCategory',
})
export class IllustrationCountCategoryPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    public transform(value: IllustrationCountCategory): string {
        return this.translateService.instant(`publisherBookIllustrationCounts.${IllustrationCountCategory[value]}`);
    }
}
