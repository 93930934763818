import { MovieChannel } from './movie-types';

export abstract class SingleImageReport {
    id?: number;
    creatorNumber: number;
    publicationYear: number;
    amountPhotos: number;
    amountArt: number;
    amountOther: number;
    date: Date;

    protected constructor(
        creatorNumber?: number,
        publicationYear?: number,
        amountPhotos?: number,
        amountArt?: number,
        amountOther?: number,
        date?: Date,
    ) {
        this.creatorNumber = creatorNumber;
        this.publicationYear = publicationYear;
        this.amountPhotos = amountPhotos;
        this.amountArt = amountArt;
        this.amountOther = amountOther;
        this.date = date;
    }
}

export class WebImageReport extends SingleImageReport {
    fullUrl: string;
    webAddress: string;

    constructor(
        creatorNumber?: number,
        publicationYear?: number,
        fullUrl?: string,
        webAddress?: string,
        amountPhotos?: number,
        amountArt?: number,
        amountOther?: number,
        date?: Date,
    ) {
        super(
            creatorNumber,
            publicationYear,
            amountPhotos,
            amountArt,
            amountOther,
            date,
        );
        this.fullUrl = fullUrl;
        this.webAddress = webAddress;
    }
}

export class PrintImageReport extends SingleImageReport {
    title: string;
    issn: string;
    medium: PrintImageMedium;

    constructor(
        creatorNumber?: number,
        publicationYear?: number,
        medium?: PrintImageMedium,
        issn?: string,
        title?: string,
        amountPhotos?: number,
        amountArt?: number,
        amountOther?: number,
        date?: Date,
    ) {
        super(
            creatorNumber,
            publicationYear,
            amountPhotos,
            amountArt,
            amountOther,
            date,
        );
        this.issn = issn;
        this.title = title;
        this.medium = medium;
    }
}

export class DigitalProductImageReport extends SingleImageReport {
    fullUrl: string;
    webAddress: string;
    issn: string;

    constructor(
        creatorNumber?: number,
        publicationYear?: number,
        issn?: string,
        fullUrl?: string,
        webAddress?: string,
        amountPhotos?: number,
        amountArt?: number,
        amountOther?: number,
        date?: Date,
    ) {
        super(
            creatorNumber,
            publicationYear,
            amountPhotos,
            amountArt,
            amountOther,
            date,
        );
        this.issn = issn;
        this.fullUrl = fullUrl;
    }
}

export enum PrintImageMedium {
    Paper = 1,
    EPaper = 2
}

export class TelevisionImageReport extends SingleImageReport {
    channel: MovieChannel;

    constructor(
        creatorNumber?: number,
        publicationYear?: number,
        channel?: MovieChannel,
        amountPhotos?: number,
        amountArt?: number,
        amountOther?: number,
        date?: Date,
    ) {
        super(
            creatorNumber,
            publicationYear,
            amountPhotos,
            amountArt,
            amountOther,
            date,
        );
        this.channel = channel;
    }
}
