import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {IdentityService} from '../identity.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private identityService: IdentityService,
    ) {
    }

    canActivate(
        _next: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return this.identityService.loginState
            .pipe(map(loggedIn => loggedIn ? true : this.redirectToLogin(_next.url.join('/'))));
    }

    redirectToLogin(origin: string): boolean {
        if (this.identityService.isCurrentlyLoggedIn()) {
            return true;
        }
        this.identityService.login(origin);
        return false;
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return this.canActivate(next, state);
    }
}

