import {ModuleWithProviders, NgModule} from '@angular/core';
import {OAuthModule} from 'angular-oauth2-oidc';
import {TokenComponent} from './token.component';

@NgModule({
    declarations: [TokenComponent],
    imports: [
        OAuthModule.forRoot({
            resourceServer: {
                sendAccessToken: true,
            },
        }),
    ],
})
export class IdentityModule {
  static forRoot(): ModuleWithProviders<IdentityModule> {
        return {
            ngModule: IdentityModule,
            providers: [],
        };
    }
}
