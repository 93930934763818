export enum ExhibitionSize {
    Small = 1,
    Medium = 2,
    Large = 3,
}

export enum ExhibitionCategory {
    MuseumsAndSimilar = 1,
    PublicSpaces = 2,
    PerformancesAndSimilar = 3,
    StreetArtAndSimilar = 4,
    Other = 5,
}

export enum ExhibitionType {
    Ifa = 1,
    Goethe = 2,
    GermanEmbassy = 3,
    Other = 4,
}

export class ExhibitionReport {
    id?: number;
    isGerman: boolean;
    title: string;
    month: number;
    year: number;
    size: ExhibitionSize;
    postcode: string;
    creatorNumber: number;
    catalogExists: boolean;
    location: ExhibitionLocation;
    type: ExhibitionType;
    category: ExhibitionCategory;
    individualCategory: string;

    constructor(
        title?: string,
        month?: number,
        year?: number,
        size?: ExhibitionSize,
        postcode?: string,
        creatorNumber?: number,
        catalogExists?: boolean,
        location?: ExhibitionLocation,
        exhibitionType?: ExhibitionType,
        exhibitionCategory?: ExhibitionCategory,
        individualCategory?: string,
    ) {
        this.title = title;
        this.month = month;
        this.year = year;
        this.size = size;
        this.postcode = postcode;
        this.creatorNumber = creatorNumber;
        this.catalogExists = catalogExists;
        this.location = location;
        this.isGerman = location ? location.country === 'DE' : false;
        this.type = exhibitionType;
        this.category = exhibitionCategory;
        this.individualCategory = individualCategory;
    }
}

export class ExhibitionLocation {
    city: string;
    country: string;
    name: string;
}
