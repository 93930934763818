

<ng-container *ngIf="pageText">
    <ckeditor #ckEditorComponent  [editor]="ckEditor"
              [data]="pageText.contents"
              [config]="ckConfig"
              [disabled]="disabled"
              (change)="ckChange($event)"></ckeditor>
    <footer>
        <b class="hint"
           [class.visible]="pageText.contents">
            <mat-icon>chat</mat-icon>Hilfetext für diese Seite
        </b>
        <b class="hint"
           [class.visible]="!pageText.contents">
            <mat-icon>chat_bubble</mat-icon>Bisher kein Hilfetext für diese Seite eingetragen
        </b>
        <span class="spacer"></span>
        <button mat-raised-button
                color="primary"
                [disabled]="saveDisabled || disabled"
                (click)="save()">veröffentlichen</button>

        <mat-progress-bar [mode]="progressBarError ? 'determinate' : 'indeterminate'"
                          [color]="progressBarError ? 'warn' : 'accent'"
                          [value]="progressBarError ? 100 : 0"
                          @progressBarFade
                          *ngIf="showProgressBar"></mat-progress-bar>
    </footer>
</ng-container>