import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  public transform(dateString: string): string {
    return moment(dateString, 'DDMMYYYYHHmm').format('DD.MM.YYYY, HH:mm');
  }
}