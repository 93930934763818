<div [formGroup]="parts">
       <ng-container *ngIf="withPrefix">
              <input type="text" class="prefix" formControlName="prefix" size="4" maxlength="3" pattern="[0-9]{3}"
                     required (blur)="onTouched()">
              <span>–</span>
       </ng-container>
       <input type="text" class="registrationGroup" formControlName="registrationGroup" size="5" maxlength="5"
              pattern="[0-9]{1,5}" required (blur)="onTouched()">
       <span>–</span>
       <input type="text" class="registrant" formControlName="registrant" pattern="[0-9]+" required resizable
              (blur)="onTouched()">
       <span>–</span>
       <input type="text" class="publication" formControlName="publication" pattern="[0-9]+" required resizable
              (blur)="onTouched()">
       <span>–</span>
       <input type="text" class="checkdigit" formControlName="checkdigit" size="2" maxlength="1" pattern="[0-9]{1}"
              required (blur)="onTouched()">
</div>