import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { UserService } from '../services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { animate, style, transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from '../shared/animations/fade';
import { shake } from 'ng-animate';
import { Router } from '@angular/router';
import { DialogService } from "../shared/dialog.service";
import { IdentityService } from "../core/auth/identity.service";
import {CreatorMigrationAlreadyInitializedError, MigrateError} from "./types/migrate-error";
import {UpdateUnverifiedEmailRequest} from "./types/update-unverified-email-request";

type MigrateForm = {
  creatorNumber: number,
  password: string,
  email: string,
  emailConfirmation: string,
}

@Component({
  selector: 'vgb-migrate',
  templateUrl: './migrate.component.html',
  styleUrls: ['./migrate.component.scss'],
  animations: [
    trigger('progressBarFade', [
      transition(':enter', [useAnimation(fadeIn)]),

      transition(':leave', [useAnimation(fadeOut)]),
    ]),
    trigger('failureMessage', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('150ms', style({ opacity: 0 }))]),
    ]),
    trigger('shakeCard', [
      transition(
        'static => shake',
        useAnimation(shake, {
          params: { timing: 0.6 },
        }),
      ),
    ]),
  ],
})
export class MigrateComponent implements OnInit {

  public formGroup: FormGroup;
  public errorMessage: string = null;
  public showProgressBar: boolean = false;
  shakeCardState: string = 'static';

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private dialogService: DialogService,
    private router: Router,
    private identityService: IdentityService,
  ) {
  }

  ngOnInit() {
    const requiredValidator = RxwebValidators.required({ message: 'Bitte machen Sie eine Angabe.' });
    const emailValidator = RxwebValidators.email({ message: 'Bitte geben Sie eine gültige E-Mail-Adresse an.' });
    let emailConfirmationValidator = RxwebValidators.compare({
      fieldName: 'email',
      message: 'Emails stimmen nicht überein.'
    });

    this.formGroup = this.formBuilder.group({
      creatorNumber: ['', [requiredValidator]],
      password: ['', [requiredValidator]],
      email: ['', [requiredValidator, emailValidator]],
      emailConfirmation: ['', [requiredValidator, emailConfirmationValidator]],
    });

    this.formGroup.get('email').valueChanges.subscribe(() => {
      this.formGroup.get('emailConfirmation').updateValueAndValidity({ onlySelf: true });
    });
  }

  submit() {
    this.errorMessage = null;
    this.showProgressBar = true;
    this.formGroup.disable();
    this.userService.migrateCreator(this.formGroup.value)
      .subscribe(() => this.onMigrationSuccess(), (e) => this.onMigrationError(e));
  }

  onMigrationSuccess() {
    this.dialogService.info(
      `Die Urhebernummer wurde erfolgreich zu einem Benutzer mit der Emailadresse ${this.formGroup.value.email} migriert.
            Sie erhalten in Kürze eine Email zum Erstellen eines Passworts.`,
    )
      .afterClosed().subscribe(() => this.router.navigate(['/', 'signin']));
  }

  onMigrationError(error: HttpErrorResponse) {
    const migrateError = error.error as MigrateError;
    this.errorMessage = this.getErrorMessage(migrateError);
    if (migrateError.exception === "CreatorMigrationAlreadyInitialized") {
      this.onMigrationAlreadyStartedError(migrateError);
    } else {
      this.formGroup.enable();
    }
    setTimeout(() => this.shakeCardState = 'shake');
  }

  onMigrationAlreadyStartedError(error: CreatorMigrationAlreadyInitializedError) {
    if (error.details.email === this.formGroup.value.email) {
      this.dialogService.confirm(
        "Die Migration für diesen Urheber wurde bereits mit der angegebenen Email gestartet. Soll die Bestätigungs-E-Mail erneut gesendet werden?",
        () => {
          this.onResendConfirmationEmailOk(this.formGroup.value.email);
        },
        () => {
          this.formGroup.enable();
        }
      );
    } else {
      this.dialogService.confirm(
        `Die Migration für diesen Urheber wurde bereits mit der E-Mail-Adresse ${error.details.email} gestartet. Soll die E-Mail-Adresse zu ${this.formGroup.value.email} geändert werden?`,
        () => {
          this.onChangeEmailOk(error.details.email);
        },
        () => {
          this.formGroup.enable()
        }
      );

    }
  }

  onResendConfirmationEmailOk(email: string) {
    this.userService.sendVerifyEmail(email).subscribe(
      () => {
        this.dialogService.info("Die Bestätiguns-E-Mail wurde erneut gesendet").afterClosed().subscribe(() => this.router.navigate(['/', 'signin']));
      },
      () => {
        this.errorMessage = "Es ist ein Fehler beim erneuten senden der Bestätigungs-E-Mail aufgetreten";
        this.formGroup.enable();
      }
    );
  }

  onChangeEmailOk(originalEmail: string) {
    const { email, ...formGroupValue } = this.formGroup.value as MigrateForm;
    const request: UpdateUnverifiedEmailRequest = {
      ...formGroupValue,
      originalEmail: originalEmail,
      newEmail: this.formGroup.value.email,
    };

    this.userService.updateUnverifiedEmail(request).subscribe(
      () => {
        this.dialogService.info("Die E-Mail-Adresse wurde erfolgreich geändert. Sie erhalten in Kürze eine Email zum Erstellen eines Passworts.")
          .afterClosed().subscribe(() => this.router.navigate(['/', 'signin']));
      },
      () => {
        this.errorMessage = "Es ist ein Fehler beim ändern der E-Mail-Adresse aufgetreten";
      }
    );
  }

  shakeEnd() {
    this.shakeCardState = 'static';
  }

  private getErrorMessage(error: MigrateError) {
    switch (error.exception) {
      case 'CreatorNotFound':
        return `Benutzer mit der Urhebernummer ${this.formGroup.value.creatorNumber} existiert nicht.`;
      case 'PasswordIncorrect':
        return 'Das eingegebene Passwort ist nicht korrekt.';
      case 'CreatorAlreadyMigrated':
        return `Die Urhebernummer ${this.formGroup.value.creatorNumber} wurde bereits migriert.`;
      case 'UserAlreadyExists':
        return `Ein Benutzer mit der Emailadresse ${this.formGroup.value.email} existiert bereits.`;
      default:
        return 'Es ist ein Fehler aufgetreten.';
    }
  }
}
