import {Pipe, PipeTransform} from '@angular/core';
import countryData from '../countryCodes.json';

@Pipe({
  name: 'getCountryName'
})
export class GetCountryNamePipe implements PipeTransform {

  public transform(code: string): string {
    return countryData.countryCodes
      .find(x => x.code === code)
      .name;
  }
}
