import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'projects/main/src/environments/environment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {IdentityService} from '../auth/identity.service';
import {Permissions} from '../auth/permissions';
import {Identity} from '../auth/types/identity';

@Component({
  selector: 'vgb-app-bar',
  templateUrl: './app-bar.component.html',
  styleUrls: ['./app-bar.component.scss'],
})
export class AppBarComponent implements OnInit, OnDestroy {
  @HostBinding('style.z-index')
  zIndex: number | string = '';

  showUserTools: boolean;
  identity: Identity;
  permissions = Permissions;
  private unsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private identityService: IdentityService
  ) {

    this.identityService.isSignedIn
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state) => {
        this.showUserTools = state;
      });
  }

  ngOnInit() {
    this.identityService.current.subscribe((i) => (this.identity = i));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  signOut() {
    this.identityService.signOut();
    this.router.navigate(['/', 'signin']);
  }
}
