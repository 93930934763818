// new env variables also need to be added to ../assets/env.template.js
export const environment = {
  baseUrl: window["env"]["baseUrl"] || 'http://localhost:4200',
  production: window["env"]["production"] === undefined
    ? false
    : window["env"]["production"] === "true",
  titlePrefix: window["env"]["titlePrefix"] || 'local',
  serviceUrl: window["env"]["serviceUrl"] || 'http://localhost:61234/api',
  identity: {
    issuer: window["env"]["issuer"] || 'http://localhost:3004/realms/meldeportal',
    requireHttps: window["env"]["requireHttps"] === undefined
      ? false
      : window["env"]["requireHttps"] === "true",
    clientId: window["env"]["clientId"] || 'webapp',
    redirectUrl: window["env"]["redirectUrl"] || 'http://localhost:4200/token',
  },
  logLevel: window["env"]["logLevel"] || 'DEBUG'
};
