import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'vgb-token',
    template: 'Sie werden eingeloggt',
})
export class TokenComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}
