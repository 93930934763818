import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {InformationDialogComponent} from "./information-dialog/information-dialog.component";
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  constructor(private dialog: MatDialog) {
  }

  public info(message: string) {
    return this.dialog.open(InformationDialogComponent, {
      data: {
        message: message,
      },
    });
  }

  public confirm(message: string, onOk: () => void, onCancel?: () => void) {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: message,
        onOk: onOk,
        onCancel: onCancel
      }
    });
  }
}
