import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BookLanguage} from 'projects/main/src/app/report/types/book-language';
import {BookType} from '../../report/types/book-type';

@Pipe({
  name: 'bookLanguages',
})
export class BookLanguagesPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  public transform(bookType: number, typeLanguageMapping: Map<BookType, BookLanguage[]>): {
    value: BookLanguage | number;
    displayText: string;
  }[] {
    const bookLanguages = typeLanguageMapping.get(bookType) || [];

    return Object.entries(BookLanguage)
      .filter(([_, languageValue]) => typeof languageValue === 'number') // Keep only numeric values
      .filter(([_, languageValue]) => bookLanguages.includes(languageValue as BookLanguage)) // Use 'as' to assert the type
      .map(([languageName, languageValue]) => ({
        value: languageValue as number, // Assert that languageValue is a number
        displayText: this.translateService.instant(`bookLanguages.${languageName}`),
      }));
  }
}


@Pipe({
  name: 'bookLanguage',
})
export class BookLanguagePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  public transform(value: BookLanguage): string {
    return this.translateService.instant(
      `bookLanguages.${BookLanguage[value]}`,
    );
  }
}
