import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialUsageModule } from '../material-usage/material-usage.module';
import { AppMenuComponent } from './app-menu.component';

@NgModule({
    declarations: [AppMenuComponent],
    imports: [
        CommonModule,
        RouterModule,
        MaterialUsageModule
    ],
    exports: [
        AppMenuComponent
    ]
})
export class AppMenuModule { }