import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '../../report/data-services/config.service';
import { MovieGenre } from '../../report/types/movie-types';
import { Observable } from 'rxjs';
import { concatAll, first, map } from 'rxjs/operators';

@Pipe({
    name: 'movieGenreName'
})
export class MovieGenreNamePipe implements PipeTransform {
    constructor(
        private configService: ConfigurationService
    ) {}

    public transform(value: number): Observable<String> {
        return this.configService.getGenres().pipe(
            concatAll(), first(genre => genre.genreId === value), map(genre => genre.name)
        );
    }
}