import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[resizable]'
})
export class ResizableInputDirective {
    @HostListener('keypress', ['$event'])
    @HostListener('keydown', ['$event'])
    @HostListener('keyup', ['$event'])
    @HostListener('change', ['$event'])
    changed(event: any) {
        this.resize(event.target.value.length);
    }

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {
        this.renderer.setAttribute(this.elementRef.nativeElement, 'size', '1');
    }

    private resize(size: number) {
        size = size || 1;
        this.renderer.setAttribute(this.elementRef.nativeElement, 'size', size.toString());
    }

}
