import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';

import {CoreModule} from '../../../core/core.module';
import {PageTextDisplayComponent} from './display/display.component';
import {PageTextEditorComponent} from './editor/editor.component';
import {PageTextComponent} from './page-text/page-text.component';

@NgModule({
    declarations: [PageTextEditorComponent, PageTextDisplayComponent, PageTextComponent],
    imports: [
        CommonModule,
        CoreModule,
        PortalModule,
        CKEditorModule
    ],
    exports: [PageTextComponent],
})
export class PageTextModule { }
