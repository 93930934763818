import {Component, Inject, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {ApiError} from '../types/api-error';

@Component({
  selector: 'vgb-user-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
  public errors: string[] = [];
  constructor(@Optional()@Inject(MAT_DIALOG_DATA) public data: ApiError = {
    StatusCode: 500,
    Message: 'Ihre Eingaben konnten nicht gesendet werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'
  }) {
  }
}
