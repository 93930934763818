<mat-toolbar class="mat-elevation-z4">
    <div id="app-bar-logoWrapper">
        <a routerLink="/" id="app-bar-logoLink">
            <img
                src="/assets/img/Logo_BK_RGB_web.png"
                alt="Logo VG Bild-Kunst"
            />
        </a>
    </div>

    <nav *ngxPermissionsOnly="[permissions.ViewTopNav]">
        <a
            *ngxPermissionsOnly="[permissions.ViewDashboard]"
            [routerLink]="['/']"
            mat-button
        >Start</a
        >
        <a
            *ngxPermissionsOnly="[
                permissions.ReportSingleWeb,
                permissions.ReportSinglePrint,
                permissions.ReportSingleTelevision,
                permissions.ReportBook,
                permissions.ReportPublisherBook,
                permissions.ReportFees,
                permissions.ReportExhibitions,
                permissions.ReportPublisherUsage,
                permissions.ReportMovies
            ]"
            mat-button
            [matMenuTriggerFor]="createReportsMenu"
        >Meldungen erfassen</a
        >

        <mat-menu #createReportsMenu="matMenu">
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ReportBook]"
                [routerLink]="['/', 'report', 'book']"
            >Buch (Urheber)</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ReportPublisherBook]"
                [routerLink]="['/', 'report', 'publisher-book']"
            >Buch (Verlag)</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ReportFees]"
                [routerLink]="['/', 'report', 'fees']"
            >Honorare</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ReportMovies]"
                [routerLink]="['/', 'report', 'movie']"
            >Film - Erstmeldung</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ReportMovies]"
                [routerLink]="['/', 'reports', 'movies']"
            >Film - Nachmeldungen</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ReportSinglePrint]"
                [routerLink]="['/', 'report', 'single-print']"
            >Einzelbilder Print</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ReportSingleWeb]"
                [routerLink]="['/', 'report', 'single-web']"
            >Einzelbilder Web</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ReportSingleTelevision]"
                [routerLink]="['/', 'report', 'single-television']"
            >
                Einzelbilder Fernsehen
            </a>
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ReportSingleDigitalProduct]"
                [routerLink]="['/', 'report', 'single-digital']"
            >Einzelbilder Digitale Verlagsprodukte</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ReportExhibitions]"
                [routerLink]="['/', 'report', 'exhibition']"
            >Werkpräsentation</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ReportArchitecturalArt]"
                [routerLink]="['/', 'report', 'architectural-art']"
            >Kunst am Bau</a
            >
        </mat-menu>

        <a
            *ngxPermissionsOnly="[
                        permissions.ViewPastReports,
                        permissions.EditPageTexts
                    ]"
            mat-button
            [matMenuTriggerFor]="ShowReportsMenu"
        >Meldungen anzeigen</a
        >

        <!--        TODO: Uncomment after import has been implemented -->
        <mat-menu #ShowReportsMenu="matMenu">
            <!--            <a-->
            <!--                mat-menu-item-->
            <!--                *ngxPermissionsOnly="[permissions.ViewReportBookPage]"-->
            <!--                [routerLink]="['/', 'reports', 'books']"-->
            <!--            >Buch (Urheber)</a-->
            <!--            >-->
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ViewReportFeesPage]"
                [routerLink]="['/', 'show-reports', 'my-fees']"
            >Honorare</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ViewReportSinglePrintPage]"
                [routerLink]="['/', 'show-reports', 'image-print']"
            >Einzelbilder Print</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ViewReportSingleWebPage]"
                [routerLink]="['/', 'show-reports', 'image-web']"
            >Einzelbilder Web</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[
                    permissions.ViewReportSingleTelevisionPage
                ]"
                [routerLink]="['/', 'show-reports', 'image-tv']">
                Einzelbilder Fernsehen
            </a>
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ReportSingleDigitalProduct]"
                [routerLink]="['/', 'show-reports', 'image-digital']"
            >Einzelbilder Digitale Verlagsprodukte</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ViewReportMoviesPage]"
                [routerLink]="['/', 'reports', 'movies']"
            >Film</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[permissions.ViewReportExhibitionsPage]"
                [routerLink]="['/', 'reports', 'exhibitions']"
            >Werkpräsentation</a
            >
            <a
                mat-menu-item
                *ngxPermissionsOnly="[
                                permissions.ViewReportArchitecturalArtPage
                            ]"
                [routerLink]="['/', 'reports', 'architectural-art']"
            >Kunst am Bau</a
            >
        </mat-menu>

        <a
            *ngxPermissionsOnly="[
                permissions.EditSelfAddress,
                permissions.EditSelfContact,
                permissions.EditSelfMail,
                permissions.EditSelfPassword,
                permissions.EditPageTexts
            ]"
            [routerLink]="['user', 'edit-self']"
            mat-button
        >Daten ändern</a
        >

        <a mat-button [matMenuTriggerFor]="ShowLegalMenu"
        >Impressum & Datenschutz</a
        >
        <mat-menu #ShowLegalMenu="matMenu">
            <a
                mat-menu-item
                href="https://www.bildkunst.de/impressum/"
            >Impressum</a
            >
            <a
                mat-menu-item
                href="https://www.bildkunst.de/datenschutz/"
            >Datenschutz</a
            >
        </mat-menu>
    </nav>

    <span class="spacer"></span>

    <div id="app-bar-userTools" *ngIf="showUserTools">
        <b>Benutzer: {{ identity.creatorNumber }}</b>

        <button mat-button (click)="signOut()">abmelden</button>
    </div>
</mat-toolbar>
