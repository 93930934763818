import { Pipe, PipeTransform } from '@angular/core';
import { BookType } from 'projects/main/src/app/report/types/book-type';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'bookTypes',
})
export class BookTypesPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  public transform(): { value: BookType | number; displayText: string }[] {
    return Object.entries(BookType)
      .filter(([key, value]) => typeof value === 'number')
      .map(([key, value]) => ({
        value: value as number,
        displayText: this.translateService.instant(`bookTypes.${key}`),
      }));
  }
}

@Pipe({
    name: 'bookType',
})
export class BookTypePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    public transform(value: BookType): string {
        return this.translateService.instant(`bookTypes.${BookType[value]}`);
    }
}
