import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from 'projects/main/src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.identity.issuer,
  oidc: true,
  requireHttps: environment.identity.requireHttps,
  clientId: environment.identity.clientId,
  scope: 'openid profile offline_access service',
  redirectUri: environment.identity.redirectUrl,
  responseType: 'code',
  postLogoutRedirectUri: environment.baseUrl,
  clearHashAfterLogin: false,
  tokenEndpoint: `${environment.identity.issuer}/protocol/openid-connect/token`,
  userinfoEndpoint: `${environment.identity.issuer}/protocol/openid-connect/userinfo`,
  showDebugInformation: true,
};

