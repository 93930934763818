import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
    selector: 'vgb-information-dialog',
    templateUrl: './information-dialog.component.html',
    styleUrls: ['./information-dialog.component.scss'],
})
export class InformationDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: {
        message: string,
    }) {
    }

    ngOnInit() {
    }

}
