import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {IdentityService} from '../identity.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private identityService: IdentityService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.identityService.redirectUrl = state.url;
    return this.isAuthenticated();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.identityService.redirectUrl = state.url;
    return this.canActivate(next, state);
  }

  private isAuthenticated(): Observable<boolean> {
    return this.identityService.isSignedIn
      .pipe(
        tap(state => {
          if (!state) {
            this.router.navigate(['/', 'signin']);
          }
        })
      );
  }
}
