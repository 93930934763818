<vgb-page-text url="/"></vgb-page-text>

<span id="dashboard-launchpad flex-1-1-auto">
  <mat-card
      class="m-b-rem-1"
      *ngxPermissionsOnly="[
        permissions.ReportSingleWeb,
        permissions.ReportSinglePrint,
        permissions.ReportSingleTelevision,
        permissions.ReportBook,
        permissions.ReportPublisherBook,
        permissions.ReportFees,
        permissions.ReportExhibitions,
        permissions.ReportMovies]">
    <mat-card-header>
      <mat-card-title>{{'dashboard.reportCard.title' | translate}}</mat-card-title>
      <mat-card-subtitle>{{'dashboard.reportCard.teaser' | translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/', 'report', 'book']" *ngxPermissionsOnly="[permissions.ReportBook]">
          <span matLine>Buchmeldungen (Urheber)</span>
          <mat-icon>chevron_right</mat-icon>
        </a>
        <a mat-list-item [routerLink]="['/', 'report', 'publisher-book']" *ngxPermissionsOnly="[permissions.ReportPublisherBook]">
          <span matLine>Buchmeldungen (Verlag)</span>
          <mat-icon>chevron_right</mat-icon>
        </a>
        <a mat-list-item [routerLink]="['/', 'report', 'fees']" *ngxPermissionsOnly="[permissions.ReportFees]">
          <span matLine>Honorarmeldungen</span>
          <mat-icon>chevron_right</mat-icon>
        </a>
        <a mat-list-item [routerLink]="['/', 'report', 'movie']"
           *ngxPermissionsOnly="[permissions.ReportMovies]">
          <span matLine>Film - Erstmeldung</span>
          <mat-icon>chevron_right</mat-icon>
        </a>
        <a mat-list-item [routerLink]="['/', 'reports', 'movies']"
           *ngxPermissionsOnly="[permissions.ReportMovies]">
          <span matLine>Film - Nachmeldungen</span>
          <mat-icon>chevron_right</mat-icon>
        </a>
          <a mat-list-item [routerLink]="['/', 'report', 'single-print']"
             *ngxPermissionsOnly="[permissions.ReportSinglePrint]">
            <span matLine>Einzelbilder Print</span>
            <mat-icon>chevron_right</mat-icon>
          </a>
        <a mat-list-item [routerLink]="['/', 'report', 'single-web']"
           *ngxPermissionsOnly="[permissions.ReportSingleWeb]">
          <span matLine>Einzelbilder Web</span>
          <mat-icon>chevron_right</mat-icon>
        </a>
        <a mat-list-item [routerLink]="['/', 'report', 'single-television']"
           *ngxPermissionsOnly="[permissions.ReportSingleTelevision]">
          <span matLine>Einzelbilder Fernsehen</span>
          <mat-icon>chevron_right</mat-icon>
        </a>
        <a mat-list-item [routerLink]="['/', 'report', 'single-digital']"
           *ngxPermissionsOnly="[permissions.ReportSingleTelevision]">
          <span matLine>Einzelbilder Digitale Verlagsprodukte</span>
          <mat-icon>chevron_right</mat-icon>
        </a>
        <a mat-list-item [routerLink]="['/', 'report', 'exhibition']"
           *ngxPermissionsOnly="[permissions.ReportExhibitions]"
        >
        <span matLine>Werkpräsentation</span>
        <mat-icon>chevron_right</mat-icon>
        </a>
        <a mat-list-item [routerLink]="['/', 'report', 'architectural-art']"
           *ngxPermissionsOnly="[permissions.ReportArchitecturalArt]">
          <span matLine>Kunst am Bau</span>
          <mat-icon>chevron_right</mat-icon>
        </a>
      </mat-nav-list>
    </mat-card-content>
  </mat-card>

    <mat-card>
        <mat-card-header>
            <mat-card-title>{{
                'dashboard.editSelfCard.title' | translate
                }}</mat-card-title>
            <mat-card-subtitle>{{
                'dashboard.editSelfCard.teaser' | translate
                }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-nav-list>
                <a
                    mat-list-item
                    [routerLink]="['/', 'user', 'edit-self']"
                    fragment="password"
                    *ngxPermissionsOnly="[
                        permissions.EditSelfPassword,
                        permissions.EditPageTexts
                    ]"
                >
                    <span matLine>Passwort</span>
                    <mat-icon>chevron_right</mat-icon>
                </a>
                <a
                    mat-list-item
                    [routerLink]="['/', 'user', 'edit-self']"
                    fragment="mail"
                    *ngxPermissionsOnly="[
                        permissions.EditSelfMail,
                        permissions.EditPageTexts
                    ]"
                >
                    <span matLine>E-Mail-Adresse</span>
                    <mat-icon>chevron_right</mat-icon>
                </a>
                <a
                    mat-list-item
                    [routerLink]="['/', 'user', 'edit-self']"
                    fragment="address"
                    *ngxPermissionsOnly="[
                        permissions.EditSelfAddress,
                        permissions.EditPageTexts
                    ]"
                >
                    <span matLine>Adresse</span>
                    <mat-icon>chevron_right</mat-icon>
                </a>
                <a
                    mat-list-item
                    [routerLink]="['/', 'user', 'edit-self']"
                    fragment="contact"
                    *ngxPermissionsOnly="[
                        permissions.EditSelfContact,
                        permissions.EditPageTexts
                    ]"
                >
                    <span matLine>Telefon</span>
                    <mat-icon>chevron_right</mat-icon>
                </a>
            </mat-nav-list>
        </mat-card-content>
    </mat-card>
</span>
