<ng-template cdkPortalOutlet></ng-template>

<ng-container *ngIf="!isIe">
    <ng-container *ngxPermissionsOnly="permissions.EditPageTexts">
        <!--todo: move-->
        <div class="page-text-debug-switch mat-elevation-z4">
            NUR FÜR ADMINISTRATOREN VERFÜGBAR<br /><br />
            <mat-slide-toggle [checked]="canEdit"
                              (change)="toggleDebug($event.checked)">Seitentext-Editor anzeigen</mat-slide-toggle>
        </div>
    </ng-container>
</ng-container>