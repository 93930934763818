import {
  AfterContentInit,
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {startWith} from 'rxjs/operators';


@Directive({
  selector: "[vgbSelectFilterOption]"
})
export class SelectFilterOptionDirective {
  @Input()
  public value: any;
  @Input()
  public rawValue: any;

  constructor(public templateRef: TemplateRef<any>) { }
}

@Component({
  selector: 'vgb-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent implements AfterContentInit {
  @Input()
  public placeholder: string;

  @Output()
  public onSelect: EventEmitter<any> = new EventEmitter<any>();

  @ContentChildren(SelectFilterOptionDirective)
  public options: QueryList<SelectFilterOptionDirective>;
  public displayOptions: SelectFilterOptionDirective[] = [];

  @Input()
  public compareWith: (o1: any, o2: any) => boolean = (o1: any, o2: any) => o1 === o2;

  @Input()
  public form: FormGroup = new FormGroup({ select: new FormControl() });
  @Input()
  public set value(val: any) {
    this.form.get(this.controlName).setValue(val);
  }

  @Input()
  filterBy: (ob: any, searchParam: string) => boolean = () => true;

  @Input()
  public controlName: string = "select";

  public searchField: string = "";

  ngAfterContentInit(): void {
    this.options.changes.pipe(startWith(null)).subscribe(x => this.applyFilter())
  }

  public applyFilter(): void {
    this.displayOptions = this.options.toArray().filter((obj) => this.filterBy(obj.rawValue, this.searchField));
  }
}
