import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'error'
})
export class ErrorPipe implements PipeTransform {

  transform(value: { [ key: string ]: { message:string }} | null): string[] {
    return value ? Object.values(value).map(x => x.message) : [];
  }

}
