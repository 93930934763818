import {registerLocaleData} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {LoggerModule} from 'ngx-logger';
import {NgxPermissionsModule} from 'ngx-permissions';

import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {RecoverPasswordModule} from './recover-password/recover-password.module';
import {SigninModule} from './signin/signin.module';
import {MatPaginatorIntlProvider} from './shared/provider/mat-paginator-intl-provider';
import {CreatorSearchModule} from './creator-search/creator-search.module';
import {MigrateModule} from './migrate/migrate.module';
import {ScrollToModule} from '@andrei4ik/ngx-scroll-to';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from "@angular/material/snack-bar";
import {MatPaginatorIntl} from "@angular/material/paginator";

registerLocaleData(localeDe, 'de');

export function createTranslateLoader(http: HttpClient) {
  const loader = new TranslateHttpLoader(http, './assets/i18n/', '.json');
  return loader;
}

export function appInitializer(translateService: TranslateService) {
  return () => {
    return translateService.use('de').toPromise();
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LoggerModule.forRoot({
      level: environment.logLevel,
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.serviceUrl],
        sendAccessToken: true
      }
    }),
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      }
    }),
    CoreModule,
    SigninModule,
    MigrateModule,
    CreatorSearchModule,
    RecoverPasswordModule,
    DashboardModule,
    ScrollToModule.forRoot(),
  ],
  providers: [
    {provide: APP_INITIALIZER, useFactory: appInitializer, deps: [TranslateService], multi: true},
    {provide: LOCALE_ID, useValue: 'de'},
    {provide: OAuthStorage, useValue: localStorage},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 4000}},
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlProvider}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
