// new env variables also need to be added to ../assets/env.template.js


export const environment = {
  production: getBooleanValue(window["env"]["production"], false),
  titlePrefix: window["env"]["titlePrefix"] || 'local',
  serviceUrl: window["env"]["serviceUrl"] || 'http://localhost:61234/api',
  identity: {
    issuer: window["env"]["issuer"] || 'http://localhost:61234/',
    tokenEndpoint: window["env"]["tokenEndpoint"] ||  'http://localhost:61234/token',
    requireHttps: getBooleanValue(window["env"]["requireHttps"], false),
    clientId: window["env"]["clientId"] || 'webapp',
  },
  logLevel: window["env"]["logLevel"] || 'DEBUG'
};

function getBooleanValue(value: string, defaultValue: boolean) {
  if (value === undefined || value === "") {
    return defaultValue;
  }
  return value === "true";
}
