import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'vgb-app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
